@use '../custom-variables/grid';
@use '../custom-variables/mixins';

.php-modules-section {
  @include mixins.header-bg-1('/img/bg/grids/grid-1.svg');
  background-position: center;

  border-radius: 20px;

  padding-block: 5rem;

  .header-block {
    display: flex;
    align-items: baseline;
    margin-bottom: 2.3rem;

    .header {
      line-height: 1.3;
      margin: 0;
    }

    i {
      margin-right: 0.4rem;
    }
  }

  .module-list {
    margin-bottom: 0;

    .module {
      font-size: 20px;
      font-weight: 400;
      line-height: 1.3;
    }
  }

  .open-btn {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.3;
    text-decoration: underline;

    margin-top: 1.5rem;

    cursor: pointer;

    max-width: fit-content;
  }

  .module-list-row {
    .module-list-col {
      @media (max-width: grid.get-grid-bp('lg')) {
        &:nth-last-child(-n+3) {
          display: none;
        }
      }

      @media (max-width: grid.get-grid-bp('md')) {
        &:nth-last-child(-n+4) {
          display: none;
        }
      }
    }
  }

  @media (max-width: grid.get-grid-bp('lg')) {
    padding-block: 3rem;

    .header-block {
      justify-content: center;
    }

    .open-btn {
      margin-inline: auto;
    }
  }

  @media (max-width: grid.get-grid-bp('md')) {
    .header-block {
      margin-bottom: 1rem;

      i {
        display: none;
      }
    }
  }
}
