@use 'colors';
@use 'grid';

/* ===== Scrollbar CSS ===== */
@mixin custom-scrollbar {
  /* Firefox */
  scrollbar-width: auto;
  scrollbar-color: colors.$satan-black colors.$sasha-gray;

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: colors.$sasha-gray;
    border-radius: 10px;
    box-shadow: inset 4px 0px 4px rgba(0, 0, 0, 0.25);
  }

  &::-webkit-scrollbar-thumb {
    background-color: colors.$satan-black;
    border-radius: 10px;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}

/* ===== transform for each browser ===== */
@mixin rotate($deg) {
  -webkit-transform: rotate($deg);
  -moz-transform: rotate($deg);
  -ms-transform: rotate($deg);
  -o-transform: rotate($deg);
  transform: rotate($deg);
}

@mixin box-sizing($opt) {
  -webkit-box-sizing: $opt;
  -moz-box-sizing: $opt;
  box-sizing: $opt;
}

@mixin transition-property($opt) {
  -webkit-transition-property: $opt;
  -moz-transition-property: $opt;
  -o-transition-property: $opt;
  transition-property: $opt;
}

@mixin transition-duration($time) {
  -webkit-transition-duration: $time;
  -moz-transition-duration: $time;
  -o-transition-duration: $time;
  transition-duration: $time;
}

@mixin transition-timing-function($opt) {
  -webkit-transition-timing-function: $opt;
  -moz-transition-timing-function: $opt;
  -o-transition-timing-function: $opt;
  transition-timing-function: $opt;
}

/* ===== Some elements/blocks Style ===== */
@mixin card-more-btn {
  content: "";
  display: block;
  float: right;
  width: var(--arrow-size);
  height: var(--arrow-size);
  transition: transform 0.2s ease-in-out;
  background-image: url("data: image/svg+xml, %3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23090909' %3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' /%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center;
  background-size: var(--arrow-size);
}

@mixin table-base {
  display: flex;
  flex-direction: column;

  width: 100%;

  .table-row {
    display: flex;
    align-items: center;

    .table-col {
      flex: 1;
    }
  }
}

@mixin table-mobile-base {
  display: none;

  @media (max-width: grid.get-grid-bp('md')) {
    display: flex;
  }

  align-items: center;
  justify-content: center;

  .table-row {}

  .table-col {
    &:first-child {
      display: grid;

      span {
        font-weight: 300;
        font-size: 18px;
        line-height: 30px;

        &:first-child {
          font-weight: 400;
          font-size: 25px;
        }
      }
    }
  }

  .table-col {
    >* {
      width: 100%;
    }

    &.swiper-col {
      display: block;
      height: 100%;

      padding-top: 30px;

      .swiper-pagination {
        position: unset;
      }
    }

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 50%;
  }
}

@mixin header-bg-1($background_url) {
  background: url($background_url) no-repeat, colors.$header-default-bg-gradient;
  background-size: 80% auto, contain;
  background-position: 0px 70px, 0px;
  //'/img/bg/grids/grid-1.svg'
}

@mixin header-gradient {
  background: colors.$header-default-bg-gradient;
  background-size: cover;
}

@mixin header-bg($primary_bg_url, $secondary_bg_url) {
  --hyper-space-background-position: 80%;

  background: url($primary_bg_url) no-repeat, url($secondary_bg_url) no-repeat, colors.$header-default-bg-gradient;
  background-position: var(--hyper-space-background-position) bottom, var(--hyper-space-background-position) top;

  @media (min-width: calc(grid.get-grid-bp('xl') + 1px)) {
    --hyper-space-background-position: 95%;
  }

  @media (max-width: grid.get-grid-bp('md')) {
    --hyper-space-background-position: 50%;
  }

  //'/img/bg/hyper-space-bg.svg'
  // '/img/bg/hyper-space-bg-alt.svg'
}

@mixin header-wiki-bg($background_url) {
  background: url($background_url) no-repeat, colors.$header-default-bg-gradient;
  background-size: 80% auto, contain;
  background-position: 0px 10px, 0px;
  //'/img/bg/grids/grid-1.svg'
}

@mixin timer-block {
  min-width: 130px;

  .timer {
    display: flex;
    align-items: baseline;

    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: -0.48px;

    [data-translation] {
      position: relative;

      &::before {
        content: attr(data-translation);
        position: absolute;

        top: 80%;

        font-size: 12px;
        font-weight: 300;
        line-height: 26px;
      }

      &[data-event-timer-name="d"]::before {
        right: 0px;
      }

      &[data-event-timer-name="h"]::before {
        left: 4px;
      }

      &[data-event-timer-name="i"]::before {
        left: 5px;
      }

      &[data-event-timer-name="s"]::before {
        left: 3px;
      }
    }
  }
}
