@use '../custom-variables/colors' as *;

.seo-section {
  padding: 0 !important;

  .seo-container {

    &:first-child {
      &.bg-odd {
        padding-top: 111px;
      }
    }

    &.bg {
      background-size: contain;

      &-odd {
        background: url('/img/bg/seo-bg.svg') no-repeat;

        padding-top: 292px;
        padding-bottom: 313px;
      }

      &-odd-revers {
        background: url('/img/bg/seo-bg-reverse.svg') no-repeat;

        padding-top: 180px;
        padding-bottom: 358px;
      }

      &-none {
        background: unset;

        .seo-text {
          padding-left: unset !important;

          padding-right: 19px !important;
        }

        padding-top: 60px;
        padding-bottom: 93px;
      }
    }

    .seo-main-header {
      text-align: center;
      margin-bottom: 125px;

      * {
        margin: 0;

        font-weight: 600;
        // font-size: 40px;
        // line-height: 55px;
        letter-spacing: -0.88px;
      }
    }

    .seo-header {
      margin-bottom: 18px;

      * {
        font-weight: 600;
        // font-size: 32px;
        line-height: 30px;


        margin: 0;
      }
    }

    .seo-content {
      $content-height: 243px;

      .svg-block {
        max-width: 404px;
        max-height: $content-height;
        padding: 0;

        border-radius: 10px;

        box-shadow: $seo-section-svg-shadow;
      }

      .seo-text {
        display: flex;
        flex-direction: column;
        padding: 0;
        align-self: start;
        padding-left: 19px;

        .text-block {

          &,
          * {
            margin: 0;

            font-weight: 400;
            font-size: 20px;
            line-height: 30px;

          }

          a {
            color: unset;
          }
        }

        .more-text-btn {
          align-self: end;

          display: flex;
          align-items: end;

          .more-btn {
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;


            text-decoration: underline;

            color: $seo-section-more-text-btn-color;

            cursor: pointer;
          }
        }
      }

      &.overflow {
        .seo-text {
          .text-block {
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;

            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
