@use '../custom-variables/grid';

.brands-section {
  padding-top: 28px;

  .brands-header {
    text-align: center;

    *,
    & {
      margin: 0;

      font-weight: 600;
      line-height: 55px;
    }
  }

  .brands-sub-title {
    text-align: center;

    *,
    & {
      margin: 0;

      font-size: 24px;
      font-weight: 300;
      line-height: 1.3;
    }
  }

  .brands-block {
    background: url('/img/bg/logos-bg.svg') no-repeat;
    background-size: cover;

    font-size: 0.9vw;

    padding-top: 9.85em;
    padding-bottom: 8em;
    padding-inline: 9.4em;

    max-width: -moz-available;
    max-width: -webkit-fill-available;

    display: inline-block;
    overflow: clip;

    text-align: center;

    @media (max-width: grid.get-grid-bp('lg')) {
      overflow: scroll;
      text-overflow: unset;
    }

    white-space: nowrap;
    text-overflow: ellipsis;

    color: transparent;

    .brands-img {
      max-height: calc(4vw + 2em);
      max-width: 200px;

      margin-inline: 0.8vw;

      filter: grayscale(100%);
      transition: 0.5s;

      &:hover {
        filter: none;
        transition: 0.5s;
      }
    }
  }
}
