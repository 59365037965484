@use '../custom-variables/colors' as *;
@use '../custom-variables/grid';

.fast-info-section {
  padding-top: 30px;

  .title {
    text-align: center;

    font-weight: 600;
    line-height: 55px;
    letter-spacing: -0.88px;
  }

  .main-block {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    gap: 2.8vw 0;

    margin-top: 4.3vw;

    @media (max-width: grid.get-grid-bp('lg')) {
      gap: 2.8vw 10px;
    }

    .info-block {
      display: flex;
      align-items: center;

      @media (max-width: grid.get-grid-bp('sm')) {
        flex-direction: column;
        text-align: center;
      }

      flex: 1 0 50%;

      .info-text {
        display: flex;
        flex-direction: column;
        justify-content: start;

        margin-left: 1.1vw;

        .theme {
          line-height: 30px;

          margin: 0;
        }

        .text {
          margin-top: 1.4vw;

          font-weight: 400;
          font-size: 20px;
          line-height: 26px;

          a {
            color: $fast-info-section-text-color;
          }
        }
      }
    }
  }

  .sub-text {
    display: flex;
    align-items: center;
    justify-content: center;

    * {
      text-align: center;

      font-weight: 300;
      font-size: 24px;
      line-height: 30px;


      color: $fast-info-section-text-color;
      max-width: 700px;
    }

    margin-top: 5rem;

    p {
      margin: 0;
    }

    a {
      color: $fast-info-section-text-color;
    }
  }
}
