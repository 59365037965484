@use '../custom-variables/colors';
@use '../custom-variables/grid';

.red-alert-left {
  background: url('/img/bg/pages-promo-section-bg.svg') no-repeat;
  background-size: cover;

//   @media (max-width: grid.get-grid-bp('sm')) {
//     background-color: #bf3940;
//     background-size: cover;
//   }
}

.red-alert-right {
  background: url('/img/bg/product-promo-section-bg.svg') no-repeat;
  background-size: cover;

//   @media (max-width: grid.get-grid-bp('sm')) {
//     background-color: #bf3940;
//     background-size: cover;
//   }
}
