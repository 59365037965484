@use '../custom-variables/colors' as *;
@use '../custom-variables/grid';

.footer-section {
  background: url('/img/bg/transparent-waves-grid.svg') no-repeat, $footer-main-bg-color;
  background-position: center;
  background-size: contain;

  color: $footer-links-color;

  .site-links {
    .links-list-block {
      display: flex;
      flex-direction: column;
      align-items: start;

      .title {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;


        margin-bottom: 1rem;
      }

      .links-list {
        list-style: none;
        padding: 0;
        margin: 0;
      }

      .link {
        color: $footer-active-links-color;

        font-size: 18px;
        font-weight: 300;
        line-height: 26px;


        a {
          color: $footer-links-color;
          text-decoration: none;
        }
      }
    }
  }

  .site-info {
    .social-col {
      display: flex;
      flex-direction: column;
      align-items: start;
    }

    .social-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .info-block {
      a {
        color: inherit;
        text-decoration: none;
      }

      >* {
        font-size: 20px;
        font-weight: 300;
        line-height: 1.4;

      }

      .copyright {
        font-size: 20px;
        font-weight: 400;
        line-height: 1.4;

      }
    }

    .social-links {
      display: flex;
      align-items: center;
      justify-content: space-between;

      min-width: 223px;

      color: $footer-links-color;

      .link {
        display: block;

        text-decoration: none;
        color: inherit;

        font-size: 43px;
      }
    }
  }

  .payment-info-block {
    max-width: 303px;

    i {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 75px;
    }
  }

  .payment-rating-block{
    @media (max-width: grid.get-grid-bp('lg')) {
      width: 100%;
    }
  }

  .fs-17-px {
    font-size: 17px;
  }

  .fs-26-px {
    font-size: 26px;
  }

  .fs-29-px {
    font-size: 29px;
  }

  .fs-32-px {
    font-size: 32px;
  }

  .fs-36-px {
    font-size: 36px;
  }

  .fs-38-px {
    font-size: 38px;
  }

  .fs-42-px {
    font-size: 42px;
  }
}
