@use '../hyper-icons/icon-variables' as icon;

.rating-section {
  .stars {
    border: none;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;

    input {
      display: none;
    }

    .star {
      font-family: 'hyper-icons';

      &::before {
        content: icon.$hypericon-star-regular;
        margin: 1px;
        font-size: 1.225em;
        display: inline-block;
      }

      &.half::before {
        content: icon.$hypericon-star-half;
        position: absolute;
      }

      &.full::before {
        content: icon.$hypericon-star-solid;
        position: absolute;
      }

      &.empty::before {
        content: icon.$hypericon-star-regular;
        position: absolute;
        max-width: calc(1.225 / 2) + em;
      }
    }
  }

}
