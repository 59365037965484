@use '../custom-variables/colors' as *;
@use '../custom-variables/grid';

.product-promo-section {
  .promo-title-block {
    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;

    margin-bottom: 20px;

    .header {
      font-weight: 600;
      //   font-size: 41px;
      line-height: 52px;
      letter-spacing: -0.88px;

      margin-bottom: 12px;
    }

    .undertitle {
      font-weight: 300;
      font-size: 24px;
      line-height: 30px;

    }
  }

  .promo-cards-block {

    .table-tabs-section {}

    .promo-cards-col {
      display: flex;
      align-items: center;
      justify-content: center;

      padding-top: 2.3vw;
      padding-bottom: 1.5vw;

      &>* {
        overflow-x: scroll;

        &::-webkit-scrollbar {
          display: none;
        }

        /* IE and Edge */
        -ms-overflow-style: none;

        /* Firefox */
        scrollbar-width: none;
      }

      .tab-pane {
        .promo-cards {
          width: min-content;
        }
      }

      .promo-cards {
        display: flex;

        padding: 50px;

        .product-promo-card {
          &:not(:last-child) {
            margin-right: 45px;
          }
        }

        .more-card {
          min-width: 310px;

          .icon {
            min-width: 184px;
            min-height: 184px;

            * {
              color: $product-promo-section-more-card-icon-color;
            }
          }
        }
      }
    }
  }

  .sub-text {

    &,
    * {
      color: inherit;

      text-align: center;

      font-weight: 400;
      font-size: 25px;
      line-height: 40px;
    }
  }

  @media (max-width: grid.get-grid-bp('lg')) {
    .sub-text {

      *,
      & {
        font-size: 1rem;
      }
    }
  }

  @media (max-width: grid.get-grid-bp('md')) {
    .sub-text {
        display: none;
    }

    .product-promo-card {
      &:not(:last-child) {
        margin-right: 1.5rem !important;
      }
    }

    .promo-cards {
      padding: 3rem 1rem !important;
    }
  }
}
