@use '../custom-variables/colors' as *;
@use 'cards';

.more-card {
  display: flex;
  flex-direction: column;
  align-items: center;

  min-width: 310px;
  max-width: 310px;

  min-height: 580px;
  max-height: 580px;

  background: $more-card-bg;
  border-radius: 30px;

  padding-top: 28px;
  padding-bottom: 43px;
  padding-inline: 50px;

  text-align: center;

  text-decoration: none;
  color: inherit;

  @include cards.card-shadow;

  .title {
    font-weight: 600;
    font-size: 26px;
    line-height: 33px;
    letter-spacing: -0.88px;

    margin-bottom: 37px;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;

    $size: 184px;

    min-width: $size;
    min-height: $size;
    max-width: $size;
    max-height: $size;

    box-shadow: inset 0px 4px 4px #0000001a;

    border-radius: 50%;

    margin-bottom: 16px;

    i {
      color: $more-card-icon-color;
      font-size: 100px;
    }
  }

  .text {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;

  }

  .go-btn {
    margin-top: auto;

    >*,
    & {
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;


      text-decoration: underline;

      color: unset;
    }
  }

  &-alt {
    @extend .more-card;

    .title {
      margin-bottom: 37px;
    }

    .icon {
      margin-bottom: 12px;
    }

    .text {
      margin-bottom: 29px;
    }

    .card-price {
      >* {
        .prefix {
          font-weight: 600;
          font-size: 16px;
          line-height: 21px;

        }

        .price {
          font-weight: 600;
          font-size: 40px;
          line-height: 60px;
          letter-spacing: -0.88px;

          margin-inline: 6px;
        }

        .watermark,
        .sufix {
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          letter-spacing: -0.88px;
        }
      }
    }
  }
}
