.grab-scroll {
  user-select: none;

  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  &.grabbing {
    cursor: grabbing;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
  }
}
