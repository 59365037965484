@use '../custom-variables/colors' as *;
@use '../custom-variables/grid';

.vpn-speed-test-section {
  background: url('/img/bg/vpn-speed-test-bg.svg') no-repeat;
  background-size: cover;

  border-radius: 20px;

  padding-top: calc(62 / 16) + rem;
  padding-bottom: calc(70 / 16) + rem;

  .header {
    text-align: center;
    margin-bottom: 4rem;

    >* {
      margin: 0;
    }
  }

  .versus-block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .vs {
      color: $vpn-speed-test-section-vs-color;

      font-weight: 600;
      font-size: 45px;
      line-height: 55px;
      letter-spacing: -0.88px;
    }
  }

  .res-block {
    display: flex;
    flex-direction: column;
    align-items: center;

    .img-block {
      max-width: 501px;
      max-height: 243px;

      video {
        max-width: 501px;
        max-height: 243px;

        border-radius: 20px;
        filter: drop-shadow(0px 5px 9px rgba(0, 0, 0, 0.25));
      }
    }

    .text {
      font-weight: 600;
      line-height: 33px;
      letter-spacing: -0.88px;

      margin-top: 1rem;
    }
  }

  @media (max-width: grid.get-grid-bp('xl')) {
    .versus-block {
      padding-inline: 41px;
    }
  }

  @media (max-width: grid.get-grid-bp('lg')) {
    .header {
      margin-bottom: 2rem;
    }

    .versus-block {
      flex-direction: column;
      justify-content: unset;

      .vs {
        margin-block: 0.6rem;
        text-align: center;
      }
    }

    .res-block {
      .img-block {
        video {
          max-width: 400px;
        }
      }
    }
  }

  @media (max-width: grid.get-grid-bp('sm')) {
    .res-block {
      .img-block {
        video {
          max-width: 300px;
        }
      }
    }
  }
}
