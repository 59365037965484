$flag-icons-path: '/img/flags/';
$flag-icons: '4x3';
$flag-icons-square-path: '1x1';
$flag-icons-use-square: false;

/**
* Add new country code if you needed, all flags already added in two formats 4x3 and 1x1
*/
$country-list: (
  'uk',
  'us',
  'bg',
  'eu',
  'pl',
  'nl',
  'de',
  'ng',
  'sg',
  'ua'
);

@mixin country($country) {
  .fi-#{$country} {
    background-image: url(#{$flag-icons-path}#{$flag-icons}/#{$country}.svg);
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    width: 1.227 * 1em;
    height: 0.96em;
    border-radius: 4px;
    border: solid 0.5px #e2e2e2;

    @if $flag-icons-use-square {
      &.fis {
        background-image: url(#{$flag-icons-path}#{$flag-icons-square-path}/#{$country}.svg);
      }
    }
  }
}

@each $country-name in $country-list {
  @include country($country-name)
}
