@use '../custom-variables/colors' as *;
@use '../custom-variables/grid';

.contact-from-label {
  color: $contact-form-label-color;

  font-size: 22px;
  font-weight: 600;
  line-height: 1.3;
}

.contact-from-control {
  border: unset;
  border-radius: 25px;

  padding-inline: 25px;

  min-height: 45px;

  &::placeholder {
    color: $contact-form-control-placeholder-color;
  }

  &,
  &::placeholder {
    font-size: 20px;
    font-weight: 300;
    line-height: 1.3;
    letter-spacing: -0.88px;
  }

  @media (min-width: grid.get-grid-bp('lg')) {
    max-width: 547px;
  }
}

.contact-from-section {
  background: $contact-form-section-bg;
  border-radius: 20px;

  .content-block {
    padding-block: 4rem;

    .header {
      margin-bottom: 2.5rem;
    }

    .desc-block {
      * {
        margin: 0;

        font-size: 20px;
        font-weight: 400;
        line-height: 1.3;


        color: $contact-form-section-desc-block-text-color;
      }

      @media (max-width: grid.get-grid-bp('lg')) {
        margin-top: 2rem;
      }
    }
  }

  .contact-from {
    .button-block {
      .button {
        max-width: 547px;
        float: right;

        @media (max-width: grid.get-grid-bp('xl')) {
          max-width: unset;
          float: unset;
        }

        width: 100%;
      }
    }

    .input-row {
      display: flex;
      align-items: center;

      margin-bottom: 1rem;

      @media (max-width: grid.get-grid-bp('xl')) {
        flex-direction: column;
        text-align: center;

        .contact-from-label {
          margin-bottom: 1rem;
        }

        .contact-from-control {
          max-width: unset;
        }
      }

      @media (max-width: grid.get-grid-bp('lg')) {
        flex-direction: row;
        text-align: unset;

        .contact-from-label {
          margin-bottom: 0;
        }
      }

      @media (max-width: grid.get-grid-bp('md')) {
        flex-direction: column;
        text-align: center;

        .contact-from-label {
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}
