@use '../custom-variables/colors' as *;

$modal-padding-top: 1em;
$modal-padding-bottom: $modal-padding-top;

.pop-ups {
  button.close {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: 0;
    cursor: pointer;
    padding: 0;
  }

  .modal-dialog {
    width: 650px !important;
    max-width: unset;
    min-width: unset;
  }

  .close {
    color: $pop-ups-close-btn-text-color;
    filter: alpha(opacity=20);
    float: right;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1;
    opacity: 0.5;
    text-shadow: 0 1px 0 $pop-ups-close-btn-text-shadow-color;
  }

  .modal-content {
    position: relative;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;

    display: flex;
    flex-direction: column;

    width: 100%;
    height: 350px;

    pointer-events: auto;

    background-color: $pop-ups-modal-content-bg;
    background-clip: padding-box;

    border: 1px solid #00000033;
    border-radius: 15px;

    outline: 0;

    padding: $modal-padding-top 1.93em $modal-padding-bottom 2.93em !important;

    .question {
      font-weight: 600;
      font-size: 41px;
      line-height: 30px;
    }

    .list {
      list-style: disc;

      margin: 0;

      * {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;

      }

      padding-left: 1.2em;
    }

    .button {
    //   padding-block: 0;
      margin: 0;

      &.button-modal {
        max-width: 163px;
        width: 100%;
        height: 45px;

        font-size: 1.1em;

        color: $pop-ups-modal-button-text-color;

        background-color: $pop-ups-modal-button-bg;
        border-color: $pop-ups-modal-button-border-color;
      }
    }

    .logo {
      left: 138px;
      position: inherit;
      color: $pop-ups-modal-content-logo-color;
      font-weight: 300;
    }
  }

  .modal-body {
    padding: 0;
  }

  .modal-1 {
    .modal-content {
      background: url('/img/pop-ups/modal-bg-1.webp') no-repeat;

      .question {
        color: $pop-ups-modal-1-question-text-color;
        margin-top: 0.875em;
      }

      .list {
        color: $pop-ups-modal-1-list-text-color;

        margin-top: 2.7em;
      }

      .button {
        margin: 0;
        margin-top: 3.7em;

        max-width: 167px;
      }

      .discount-block {
        float: right;

        display: flex;
        align-items: start;
        flex-direction: column;

        .discount {
          color: $pop-ups-discount-color;

          font-weight: 400;
          font-size: 110px;
          line-height: 40px;

          .percent {
            font-size: 70px;
          }

          .minus {
            font-size: 80px;
          }
        }

        .promo {
          color: $pop-ups-promo-color;
          margin-left: 1.9em;
          margin-top: 11px;

          font-weight: 300;
          font-size: 18px;
          line-height: 30px;


          .code {
            font-weight: 400;
          }
        }
      }
    }
  }

  .modal-2 {
    .modal-content {
      background: url('/img/pop-ups/modal-bg-2.webp') no-repeat;

      .question {
        color: $pop-ups-modal-2-question-text-color;
        margin-top: 0.7em;
      }

      .list {
        color: $pop-ups-modal-2-question-text-color;
        font-size: 1.125em;

        margin-top: 1.8em;
      }

      .button-modal {
        margin-top: 5.1em;
        margin-left: 0.6em;
      }

      .modal-image {
        bottom: 45px;
        position: relative;
        left: 51px;
      }
    }
  }

  .modal-3 {
    .modal-content {
      background: url('/img/pop-ups/modal-bg-3.webp') no-repeat;

      .question {
        color: $pop-ups-modal-3-question-text-color;
        margin-top: 0.42em;
      }

      .list {
        color: $pop-ups-modal-3-list-text-color;
        font-size: 1em;

        margin-top: 2.5em;
      }

      .button-modal {
        margin-top: 5.4em;
        margin-left: 0.6em;
      }

      .modal-image {
        top: 18px;
        position: relative;
        left: 44px;
      }
    }
  }
}
