@use '../custom-variables/colors' as *;
@use '../custom-variables/grid';

.hyper-timer {
  background: url('/img/bg/timer-small-bg.svg') no-repeat, url('/img/bg/timer-section.svg') no-repeat;
  background-size: auto, cover;
  background-position: 105% 63.5%, center;

  padding-top: 125px;
  padding-bottom: 207px;

  @media (max-width: grid.get-grid-bp('xl')) {
    background-position: 105% 61%, center;
  }

  .title {
    font-weight: 600;
    // font-size: 40px;
    line-height: 55px;
    letter-spacing: -0.88px;

    text-align: center;
  }

  .time-block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 5.9vw;
    margin-bottom: 0.9vw;

    >div {
      width: 188px;
      text-align: center;

      font-weight: 600;
      font-size: 36px;
      line-height: 41px;
      letter-spacing: -0.88px;
      color: $timer-main-color;
    }
  }

  .time_circles {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .time-count {
      position: absolute;
      font-weight: 600;
      margin-left: -3.5em;
      margin-top: 1.3em;
      text-align: center;
      width: 183px;
      font-size: 52px;
      line-height: 41px;
      letter-spacing: -1.61505px;
      color: $primary-blue;
    }
  }
}
