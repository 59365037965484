@use '../hyper-icons/icon-variables' as font;
@use '../custom-variables/colors' as *;

.breadcrumb {
  display: flex;
  align-items: center;

  padding-top: 12px;
  padding-bottom: 28px;
  margin: 0;

  .breadcrumb-item {
    color: $breadcrumbs-text-color;

    a {
      color: inherit;
    }

    [itemprop="name"] {
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
    }

    &::before {
      content: font.$hypericon-angle-li-right;

      font-family: font.$icomoon-font-family;

      color: $breadcrumbs-text-color;

      float: left;
      padding-right: 5px;
    }
  }
}
