@use '../custom-variables/colors' as *;

.img-into-svg {

    &,
    * {
        color: $elips-icon-color;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 35px;
        line-height: 40px;
        text-align: center;
    }

    * {
        height: 100%;
        width: 100%;
    }
}
