@use '../custom-variables/colors' as *;

.cms-section {
  .cms-header {
    * {
      font-weight: 600;
    //   font-size: 48px;
      line-height: 55px;
      letter-spacing: -0.88px;
    }

    a {
    //   font-size: 20px;
      font-weight: 400;
      line-height: 1.5;
    }

    text-align: center;
  }

  .cms-swiper-block {
    display: flex;
    align-items: center;

    margin-top: 76px;

    .btn {

      &-prev,
      &-next {
        font-size: 30px;
        cursor: pointer;
      }

      &-prev {
        transform: rotate(180deg);
        margin-right: 65px;
      }

      &-next {
        margin-left: 65px;
      }
    }

    .cms-swiper {
      display: flex;
      align-items: center;

      overflow: scroll;

      scrollbar-width: 0;

      // /* Chrome, Edge, and Safari */
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      .cms-image {
        text-align: center;
        font-size: 105px;
        color: $cms-section-icons-color;
      }
    }
  }
}
