@use '../custom-variables/colors' as *;
@use '../custom-variables/grid';

.table-tabs-section {
  @media (max-width: grid.get-grid-bp('md')) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .table-tabs-block {
    display: flex;
    align-items: center;
    justify-content: center;

    padding-inline: 25px;
    padding-block: 15px;

    background: $table-tabs-block-bg-color;
    box-shadow: $table-tabs-block-shadow;

    border-radius: 15px;

    @media (max-width: grid.get-grid-bp('md')) {
      flex-direction: column;
      width: 60%;
    }

    .table-tab {
      display: flex;
      align-items: center;
      justify-content: center;

      background: $table-tabs-bg-color;

      border: unset;
      border-radius: 10px;

      box-shadow: $table-tabs-shadow;

      color: $table-tabs-text-color;

      width: 100%;
      height: 100%;

      max-width: 200px;
      max-height: 37px;

      padding-block: 4px;

      font-weight: 400;
      font-size: 20px;
      line-height: 30px;


      &:not(:last-child) {
        margin-right: 29px;

        @media (max-width: grid.get-grid-bp('md')) {
          margin-right: 0;

          margin-bottom: 14px;
        }
      }

      &:is(a) {
        text-decoration: none;
      }

      &:hover {
        box-shadow: none;
      }

      &.active {
        background: $table-tabs-bg-active-color;
        color: $table-tabs-text-active-color;
        box-shadow: $table-tabs-shadow;
      }
    }
  }
}
