@use '../custom-variables/colors' as *;
@use '../custom-variables/grid';
@use '../custom-variables/mixins' as timer;

.coupon-promocode-section {
  .coupon-promocode-block {
    border-radius: 20px;
    min-height: 214px;
    padding: 32px 70px;

    @media (max-width: grid.get-grid-bp('md')) {
      padding: 32px;
    }

    @media (max-width: grid.get-grid-bp('sm')) {
      padding: 32px 24px;
    }

    .coupon-promocode-header {
      >* {
        margin: 0;
      }
    }

    .coupon-promocode-icon {
      font-size: 1.77rem;
      margin-right: 1rem;
    }

    .coupon-promocode-text {
      font-size: 20px;
      font-weight: 400;
      line-height: 1.2;

      >* {
        font: inherit;
        color: inherit
      }
    }

    &.gradient-blue {
      background: $gradient-blue;
    }

    &.gradient-azure {
      background: $gradient-azure;
    }

    &.gradient-aqua {
      background: $gradient-aqua;
    }
  }

  .timer-block {
    @include timer.timer-block;
  }
}
