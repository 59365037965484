@use '../custom-variables/grid';

.google-reviews-section {
  @media (max-width: grid.get-grid-bp('sm')) {
    display: none;
  }

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-bottom: 77px;

    * {
      font-weight: 600;
      line-height: 55px;
      letter-spacing: -0.88px;
    }
  }

  .google-reviews {
    margin-bottom: 122px;

    padding-block: 1.5em;
  }

  .google-reviews-swiper-pagination {
    --swiper-pagination-bottom: 5rem;
  }

  .google-reviews-swiper {
    padding-inline: 2em;
  }

  .review-cards-bg {

    &::before,
    &::after {
      margin-top: -16.8em;
      max-width: unset;
      width: 11.3%;
    }

    &::before {
      background: url('/img/bg/reviews-bg-frame-sm.svg') no-repeat;
      background-size: 100% auto;
      background-position: left bottom;
    }

    &::after {
      background: url('/img/bg/reviews-bg-frame-sm.svg') no-repeat;
      background-size: 100% auto;
      background-position: right bottom;
    }
  }
}
