@use '../custom-variables/grid';
@use '../custom-variables/colors' as *;

.citation-block {
  display: flex;
  align-items: center;

  background: $citation-section-bg;

  border-radius: 20px;

  padding-top: 3.2rem;
  padding-right: 7.8rem;

  position: relative;

  .img-block {
    padding-left: 2rem;
    position: relative;

    width: 30%;
    min-width: 410px;

    &::before {
      content: '';
      position: absolute;

      width: 365px;
      height: 100%;

      background: url('/img/bg/people-bg.svg') no-repeat;
      background-size: contain;
      background-position: bottom;

      z-index: 0;
    }

    .quotes-block {
      position: absolute;

      right: 0;
      top: 0;

      max-width: 68px;
      max-height: 68px;

      >* {
        color: $citation-section-quotes-color;

        font-size: 68px;
      }
    }

    >img,
    >picture {
      position: relative;
      z-index: 1;
    }
  }

  .text-block {
    margin-left: 3rem;

    .title {

      &,
      >* {
        color: $citation-section-title-color;
        line-height: 1.2;

      }

      >* {
        margin-bottom: 0;
      }

      margin-bottom: 2.3em;
    }

    .citation-card {
      margin-bottom: 3rem;
    }

    .person {
      .vr {
        .person-info {
          .name {}

          .speciality {}
        }
      }
    }
  }

  @media (max-width: grid.get-grid-bp('xl')) {
    padding: 3rem 20px;
    padding-bottom: 0;

    .img-block {
      padding-left: 0;
    }

    .text-block {
      margin-left: 0;
    }

    &::before {
      content: unset;
    }
  }

  @media (max-width: grid.get-grid-bp('lg')) {
    padding-bottom: 3rem;

    .text-block {
      margin: 0;

      .citation-card {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: grid.get-grid-bp('md')) {
    padding-block: 1.5rem;
  }
}

.citation-card {
  background: $citation-section-citation-card-bg;
  border-radius: 10px;
  padding: 24px;

  max-height: 258px;
  min-height: 258px;

  height: 100%;
  width: 100%;

  .citation {

    &,
    * {
      font-size: 20px;
      font-weight: 400;
      line-height: 1.3;
    }

    color: $citation-section-citation-card-text-color;

    ol,
    ul {
      margin-bottom: 0;
    }

    a {
      color: inherit;
    }
  }

  @media (max-width: grid.get-grid-bp('md')) {
    max-height: unset;
  }
}
