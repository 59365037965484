@use '../custom-variables/colors' as *;
@use '../custom-variables/grid' as gd;

.domain-check {
  display: flex;
  align-items: center;

  .domain-check-label {
    font-weight: 600;

  }

  .actions-block {
    display: flex;

    width: 75%;
  }

  .domain-input {
    border: 2px solid transparent;
    border-radius: 25px;
    outline: 0;

    padding-inline: 10px;
    height: 45px;

    width: 100%;

    max-width: 546px;

    font-size: 20px;
    font-weight: 300;
    font-family: 'Noto Sans', sans-serif;

    &:focus {
      outline: 0;
    }

    color: $domain-search-from-input-text-color;

    &::placeholder {
      color: $domain-search-from-input-placeholder-color !important;
    }
  }

  @media (max-width: gd.get-grid-bp('lg')) {
    flex-direction: column;
    align-items: start;

    .actions-block {
      width: 100%;
    }

    .domain-check-label {
      margin-right: 0;
      margin-bottom: 1rem;
    }

    .domain-input {
      max-width: 476px;

      margin-right: 2rem;
    }
  }

  @media (max-width: gd.get-grid-bp('md')) {
    align-items: center;

    .actions-block {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    .domain-input {
      margin-right: 0;
      margin-bottom: 2rem;
    }

    .domain-input,
    .domain-check-submit-btn {
      max-width: 440px;
    }

    .domain-check-submit-btn {

      &,
      >* {
        width: 100%;
      }
    }
  }
}
