@use '../custom-variables/colors' as *;
@use '../custom-variables/grid';
@use '../custom-variables/button-variables' as btn-var;
@use '../custom-variables/mixins' as mix;

.concurents-table {
  @include mix.table-base;

  &.page-concurents,
  &.domain-concurents {
    @media (max-width: grid.get-grid-bp('md')) {
      display: none;
    }
  }

  &.domain-concurents {
    .table-head {
      .table-col {
        min-height: 67px;

        font-size: 20px;
        font-weight: 400;
        line-height: 1.3;
      }
    }

    .table-body {
      .table-row {
        background: $concurents-table-row-bg;
        border-radius: 10px;

        min-width: max-content;

        &:first-child {
          margin-bottom: 5px;
        }

        .table-col {
          .price-block {
            font-weight: 600;
            line-height: 1.3;

            .price {
              font-size: 40px;
            }

            .watermark,
            .sufix {
              font-size: 30px;
            }
          }

          &.hyper {
            .price-block {
              font-weight: 600;
              line-height: 1.3;

              .price {
                font-size: 40px;
              }

              .watermark,
              .sufix {
                font-size: 30px;
              }
            }
          }

          &:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;

            padding-left: 25px;
          }

          &:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }
      }

      .table-col {
        min-height: 161px;

        .row-title {
          .title {
            font-size: 25px;
            font-weight: 400;
            line-height: 1.3;
          }
        }

        &.hyper {
          position: relative;

          >* {
            z-index: 1;
            color: $concurents-table-hyper-col-text-color;
          }

          &::before {
            content: '';
            position: absolute;
            z-index: 0;

            width: 240px;
            min-height: 213px;

            top: -0.5rem;

            background: url('/img/bg/concurents-table-col.svg') no-repeat;
            background-size: contain;
          }
        }
      }
    }

    .table-footer {
      .table-col {
        height: 82px;

        padding-top: 21px;
        padding-bottom: 16px;

        &.hyper {
          position: relative;

          >* {
            z-index: 1;
          }

          &::before {
            content: '';
            position: absolute;
            z-index: 0;

            width: 240px;
            min-height: 100px;

            border-radius: 20px;

            background: url('/img/bg/concurents-table-col.svg') no-repeat;
            background-size: cover;
          }
        }
      }
    }

    .table-row {
      .table-col {
        &:first-child {
          max-width: 290px;
          min-width: 290px;
        }

        &:not(:first-child, :last-child) {
          margin-right: 50px;
        }
      }
    }


    @media (max-width: grid.get-grid-bp('xl')) {
      .table-body {
        .table-row {
          .table-col {
            &:first-child {
              padding-inline: 12px;
            }

            &.hyper {
              &::before {
                background: url('/img/bg/concurents-table-col-sm.svg') no-repeat;
                background-size: cover;
                width: 203px;
                top: -0.6rem;

                border-radius: 20px;
              }
            }
          }
        }
      }

      .table-footer {
        .table-col {
          &.hyper {
            &::before {
              background: url('/img/bg/concurents-table-col-sm.svg') no-repeat;
              background-size: cover;
              width: 203px;
            }
          }
        }
      }

      .table-row {
        .table-col {
          &:first-child {
            max-width: 249px;
            min-width: 249px;
          }

          &:not(:first-child, :last-child) {
            margin-right: 48px;
          }
        }
      }

      .table-col {
        min-width: 203px;
        max-width: 203px;
      }
    }
  }

  &.page-concurents {
    .table-head {
      .table-col {
        min-height: 60px;

        font-size: 25px;
        font-weight: 400;
        line-height: 1.3;

        &.hyper {
          &::before {
            content: unset;
          }
        }
      }
    }

    .table-body {
      .table-row {

        &:nth-child(1),
        &:nth-child(2) {
          .table-col {
            min-height: 80px;
            max-height: 80px;

            &.hyper {
              &::before {
                min-height: 110px;
                top: -0.5rem;
              }
            }
          }
        }

        &:nth-child(1) {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;

          .table-col {
            .price-block {
              font-size: 40px;
              font-weight: 600;
              line-height: 1.3;

              .watermark {
                font-size: 30px;
              }
            }

            &.hyper {
              .price-block {
                font-weight: 600;
              }
            }
          }
        }

        &:nth-child(2) {
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;

          .table-col {
            .price-block {
              font-size: 25px;
              font-weight: 300;
              line-height: 1.3;
            }

            &.hyper {
              .price-block {
                font-weight: 400;
              }
            }
          }
        }



        &:nth-child(n+3) {
          .table-col {
            &.hyper {
              font-size: 20px;
              font-weight: 600;
              line-height: 1.3;

              &::before {
                min-height: 80px;
              }
            }
          }

          .row-title {
            .title {
              font-size: 18px;
              font-weight: 400;
              line-height: 1.3;
            }
          }
        }

        .table-col {
          min-height: 50px;
          max-height: 50px;
        }

        &:nth-child(1),
        &:nth-child(2),
        &:hover {
          background: $concurents-table-row-bg;
        }
      }
    }

    .table-footer {
      .table-col {
        min-height: 82px;
        max-height: 82px;

        &.hyper {
          &::before {
            min-height: 100px;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
          }
        }
      }
    }

    .table-row {
      padding-right: 11px;

      min-width: max-content;

      .table-col {
        &:first-child {
          max-width: 296px;
          min-width: 296px;

          justify-content: start;

          padding-left: 1.6rem;
        }

        &:not(:first-child, :last-child) {
          margin-right: 44px;
        }
      }
    }

    .table-col {
      &.hyper {
        position: relative;

        >* {
          z-index: 1;
          color: $concurents-table-hyper-col-text-color;
        }

        &::before {
          content: '';
          position: absolute;
          z-index: 0;

          width: 240px;
          min-height: 100px;

          top: -0.5rem;

          background: url('/img/bg/concurents-table-col.svg') no-repeat;
          background-size: cover;
        }
      }

      .row-title {
        display: flex;
        flex-direction: column;
        justify-content: start;

        .title {
          font-size: 25px;
          font-weight: 400;
          line-height: 1.3;
        }

        .sub-title {
          font-size: 18px;
          font-weight: 300;
          line-height: 1.3;
        }
      }
    }

    @media (max-width: grid.get-grid-bp('xl')) {
      .table-body {
        .table-row {
          .table-col {
            &:first-child {
              padding-left: 1rem;
            }

            &.hyper {
              &::before {
                background: url('/img/bg/concurents-table-col-sm.svg') no-repeat;
                background-size: cover;
                width: 203px;
                top: -0.6rem;

                border-radius: 20px;
              }
            }
          }

          &:nth-child(1),
          &:nth-child(2) {

            .table-col {
              &.hyper {
                &::before {
                  min-height: 120px;
                }
              }
            }
          }

          &:nth-child(n+3) {

            .table-col {
              &.hyper {
                &::before {
                  min-height: 90px;
                }
              }
            }
          }
        }
      }

      .table-footer {
        .table-col {
          &.hyper {
            &::before {
              background: url('/img/bg/concurents-table-col-sm.svg') no-repeat;
              background-size: cover;
              width: 203px;
            }
          }
        }
      }

      .table-row {
        .table-col {
          &:first-child {
            max-width: 249px;
            min-width: 249px;
          }

          &:not(:first-child, :last-child) {
            margin-right: 48px;
          }
        }
      }

      .table-col {
        min-width: 203px;
        max-width: 203px;
      }
    }
  }

  .table-col {
    flex: 1;

    min-width: 240px;
    max-width: 240px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.concurents-table-mobile {
  @include mix.table-mobile-base;

  .table-col {
    &:first-child {
      background: url('/img/bg/concurents-mobile-column-bg.svg') no-repeat;
      background-size: cover;
      background-position: 0 40px;
      border-radius: 15px;
    }

    .table-row {
      color: white;

      .title {
        font-size: 12px;
        font-weight: 400;
        line-height: 1.3;
      }

      .sub-title {
        font-size: 20px;
        font-weight: 600;
        line-height: 1.3;
      }

      &:nth-child(1) {
        .price-block {
          .price {
            font-size: 28px;
            font-weight: 600;
            line-height: 1.3;
          }

          .watermark,
          .sufix {
            font-size: 18px;
            font-weight: 600;
            line-height: 1.3;
          }
        }
      }

      &:nth-child(2) {
        .price-block {
          font-size: 18px;
          font-weight: 400;
          line-height: 30px;
        }
      }
    }
  }

  .table-footer {
    .order-btn {
      .button {
        --bs-btn-padding-y: #{btn-var.$btn-padding-y-sm};
        --bs-btn-padding-x: #{btn-var.$btn-padding-x-sm};
        --bs-btn-font-size: #{btn-var.$btn-font-size-sm};
        --bs-btn-border-radius: 30px;
        line-height: var(--bs-btn-line-height-sm);
        min-width: unset;
      }
    }
  }

  .table-footer,
  .swiper-pagination {
    display: flex;
    align-items: center;
    justify-content: center;

    max-height: 51px;
    min-height: 51px;
    padding-block: 8px;
  }

  .swiper-pagination {
    padding-block: 16px 0px;
    min-height: unset;
  }

  .table-head,
  .competitors {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 90px;
      max-height: 40px;
    }
  }

  .table-head {
    min-height: 40px;
  }

  .table-body {
    padding-top: 1rem;
  }

  .table-body .table-row,
  .competitors-data .competitor-data {
    min-height: 51px;
    max-height: 51px;

    &:nth-child(-n + 2) {
      min-height: 80px;
      max-height: 80px;
    }
  }

  .table-row,
  .competitor-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-inline: 20px;
  }

  .competitor-info {
    .competitors {
      .competitor-name {
        font-size: 18px;
        font-weight: 400;
        line-height: 1.3;
      }
    }

    .competitors-data {
      .competitor-data {

        &:nth-child(-n + 2),
        &:nth-child(even) {
          background: $concurents-table-row-bg;
        }

        &:nth-child(1) {
          .price-block {
            .price {
              font-size: 28px;
              font-weight: 600;
              line-height: 1.3;
            }

            .watermark,
            .sufix {
              font-size: 18px;
              font-weight: 600;
              line-height: 1.3;
            }
          }
        }

        &:nth-child(2) {
          .price-block {
            font-size: 18px;
            font-weight: 300;
            line-height: 1.3;
          }
        }

        font-size: 18px;
        font-weight: 400;
        line-height: 1.3;

        color: $concurents-mobile-table-col-text-color;
      }
    }
  }

  &.domain-concurents {
    .block-arrow {
      &::before {
        content: unset;
      }
    }
  }

  &.page-concurents {
    .block-arrow {

      --arrow-size: 3rem;
      --arrow-height: 1rem;

      &::before {
        content: '';

        top: 100%;
      }
    }

    .table-col:first-child {
      background: unset;

      .table-body {
        background: $concurents-mobile-table-hyper-col-bg;

        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
      }

      .table-footer {
        background: $concurents-mobile-table-hyper-col-bg;

        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
      }
    }
  }
}
