@use '../custom-variables/grid';

.reviews-section {
  margin-bottom: 6em;

  @media (max-width: grid.get-grid-bp('sm')) {
    display: none;
  }

  h2 {
    margin-bottom: 2em;
  }

  .swiper {
    padding-inline: 2em;

    .swiper-slide {
      height: unset;
    }
  }

  .review-cards {
    padding-block: 2.5em;
  }

  .swiper-pagination {
    position: relative;
  }
}

.reviewers-block {
  @media (max-width: grid.get-grid-bp('sm')) {
    display: none;
  }
}

.review-cards-bg {
  &::before {
    content: '';
    position: absolute;
    left: 0;
    z-index: -1;

    background: url('/img/bg/reviews-bg-frame.svg') no-repeat;
    background-size: 100% auto;
    background-position: left bottom;

    height: 100%;
    width: 11.3%;

    margin-top: -29.5em;

    -webkit-transform: none;
    transform: none;
  }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    z-index: -1;

    background: url('/img/bg/reviews-bg-frame.svg') no-repeat;
    background-size: 100% auto;
    background-position: right bottom;

    height: 100%;
    width: 11.3%;

    margin-top: -29.5em;

    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
}
