@use '../custom-variables/colors' as *;
@use '../custom-variables/grid';

.promo-swiper-section {}

.promo-swiper {
  @media (max-width: grid.get-grid-bp('md')) {
    max-width: 357px;
  }
}

.promo-swiper-slide {
  display: flex;
  align-items: start;
  justify-content: space-between;

  padding: 45px 90px 37px 90px;

  background: $promo-swiper-bg;
  border-radius: 20px;

  max-height: 300px;
  min-height: 300px;
  height: 100%;

  width: 100%;

  .text-block {
    max-width: 758px;
    padding-top: 20px;

    .title {
      color: $promo-swiper-title-color;

      font-size: 32px;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: -0.88px;
    }

    .text {
      margin-top: 39px;

      color: $promo-swiper-text-color;

      font-size: 25px;
      line-height: 30px;

      a {
        color: inherit;
      }
    }
  }

  .img-block {
    img {
      max-height: 212px;
    }
  }

  .img-block-sm {
    display: none;
    margin-top: 20px;

    max-height: 60px;
  }

  @media (max-width: grid.get-grid-bp('lg')) {
    align-items: center;
    justify-content: unset;
    flex-direction: column;
    padding-inline: 50px;

    .text-block {
      text-align: center;
      padding-top: 0;

      .text {
        margin-top: 20px;
      }
    }

    .img-block {
      display: none;
    }

    .img-block-sm {
      display: block;
    }
  }

  @media (max-width: grid.get-grid-bp('md')) {
    max-height: 255px;
    min-height: 255px;

    padding-inline: unset;
    padding: 65px 10px 70px 10px;

    .text-block {
      .text {
        display: none;
      }
    }

    .img-block-sm {
      margin-top: auto;
    }
  }
}
