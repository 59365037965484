// --------------------- Main Colors --------------------- //

// blacks
$primary-black: #090909;
$satan-black: #333333;

// grays
$sasha-gray: #F2F2F2;

// white
$primary-white: #fff;

// blues
$primary-blue: #365B95;
$primary-blue-101: #4E6481;
$primary-blue-100: #486BA0;
$primary-blue-202: #59739C;
$primary-blue-200: #7CA2D2;
$primary-blue-300: #A5C9FD;
$primary-blue-400: #BEDBFD;
$primary-blue-500: #D9ECFF;

$zima-blue: #AED5E9;

// greens
$primary-green: #369578;
$lite-green: #C1E7DB;

// red
$opel-ampera-red: #C6262F;
$opel-ampera-red-100: #BA1422;
$opel-ampera-red-200: #BF3940;
$opel-ampera-red-300: #C6262E;

$opel-ampera-red-light: #E7212B;
$opel-ampera-red-lite: #FAB0B4;

$primary-orange: #E7711B;
$lite-orange: #FFC093;

// --------------------- Main Colors End --------------------- //

// --------------------- Bootstrap variables Start --------------------- //
$primary-blue-light: $primary-blue-300;

$blue: $primary-blue;
$blue-light: $primary-blue-light;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: $opel-ampera-red;
$orange: $primary-orange;
$yellow: #ffc107;
$green: $primary-green;
$teal: #20c997;
$cyan: #0dcaf0;

$white: $primary-white;


$gray-100: #F8FAFD;
$gray-200: $sasha-gray;
$gray-300: #EEEEEE;
$gray-400: #dee2e6;
$gray-500: #e9ecef;
$gray-600: #DEDEDE;
$gray-700: #B1B1B1;
$gray-800: #727272;
$gray-900: $primary-black;

$black: $primary-black;

$grays: (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
);

$primary: $blue;
$primary-light: $blue-light;
$secondary: $gray-600;
$tool-secondary: $gray-700;
$success: $green;
$success-lite: $lite-green;
$info: $cyan;
$warning: $yellow;
$warning-lite: $lite-orange;
$danger: $red;
$danger-lite: $opel-ampera-red-lite;
$light: $gray-100;
$dark: $satan-black;

$theme-colors: (
    "primary": $primary,
    "primary-light": $primary-light,
    "secondary": $secondary,
    "tool-secondary": $tool-secondary,
    "success": $success,
    "success-lite": $success-lite,
    "info": $info,
    "real-warning": $warning,
    "warning": $opel-ampera-red-200,
    "warning-lite": $warning-lite,
    "danger": $danger,
    "danger-lite": $danger-lite,
    "light": $light,
    "dark": $dark
);

// --------------------- Bootstrap variables End --------------------- //

// _mixins, citation-section, _benefits-card, domains page
$header-default-bg-gradient: linear-gradient(0deg, #A1C4FD 0%, #C2E9FB 100%);
$cards-gray: $gray-800;

// --------------------- Components Start --------------------- //

/*                       Includes Components Start            */
// File: _nav-var
$links-color: $gray-600;

$link-bar-bg: $primary-blue;

$link-bar-list-btn-text-color: $white;
$link-bar-list-border-color: 1px solid $gray-700;

$link-bar-list-item-text-color: $gray-700;
$link-bar-list-item-text-color-hover: $satan-black;
$link-bar-list-item-text-color-active: $satan-black;

$link-bar-list-item-border-color-hover: $gray-100;

$link-bar-nav-bar-text-color: $white;

$link-bar-nav-bar-mobile-bars-color: $satan-black;
$link-bar-nav-bar-mobile-bars-not-collapsed-bg: $gray-600;

$link-bar-active-currency-text-color: $satan-black;



// File: _header
$header-secondary-nav-bg: $primary-blue-500;

$header-page-desc-text: #{$primary-blue} + 80;
$header-page-benefits-icon: $primary-blue;
$header-page-benefits-text: $satan-black;
$header-page-text-color: $satan-black;
$header-page-title-and-price-color: $primary-blue;

$header-rounded-block-border: 1px solid $primary-blue-400;

$header-domain-search-form-block-bg: rgba(255, 255, 255, 0.10);

// .cms-icon
$header-right-section-cms-icon: $blue;

// .under-header-section
$under-header-section-bg: $primary-blue;

// .page-benefits
$underheader-section-icon-and-text-color: $primary-blue-light;
$under-header-section-icon-color: $underheader-section-icon-and-text-color;
$under-header-section-text-color: $underheader-section-icon-and-text-color;
$under-header-section-page-benefits-icon-shadow: inset 0px 4px 4px #00000040;

// .top-domains
$under-header-section-top-domains-text: $white;

// .domain-search
$under-header-section-domain-search-form-block-label: $primary-blue;
$under-header-section-domain-search-label: $white;
$under-header-section-domain-search-output-bg: $white;
$under-header-section-domain-search-output-text: $satan-black;
$search-status-free: $green;
$search-status-busy: $red;



// File: _footer
$footer-main-bg-color: $primary-blue;
$footer-links-color: $white;
$footer-active-links-color: $gray-600;



// File: _seo-section
$seo-section-svg-shadow: -3.2px 2.4px 21px rgba(0, 0, 0, 0.2);
$seo-section-more-text-btn-color: $primary-blue;



// File: _faqs-section
$faq-section-question-text-color: $black;
$faq-section-question-bg: $white;



// File: _action-block
// .with-text
$action-block-with-text-type-header-text-color: $blue;
$action-block-with-text-type-main-text-color: $primary-blue-101;

//.with-buttons
$action-block-with-buttons-type-header-text-color: $primary-blue-101;
$action-block-with-buttons-type-vr-color: $primary-blue-101;



// File: _pages-promo-section
$pages-promo-section-title-color: $black;



// File: _product-promo-section
$product-promo-section-more-card-icon-color: $primary-blue;



// File: _table
$table-head-text: $primary-blue;
$table-head-bg: $gray-100;
$table-row-text: $satan-black;
$table-row-bg-hover: linear-gradient(180deg, #F7F7F7 0%, #FCFDFE 6.77%);
$table-row-bg: #{$gray-100} + 80;
$table-row-available: $green;
$table-row-not-available: $opel-ampera-red-200;
$table-row-old-price: $gray-700;
$table-row-border-for-promouted-vds: #{$opel-ampera-red-300}86;



// File: _secondary-nav
$secondary-nav-text-color: $primary-blue;

$secondary-nav-title-color: $secondary-nav-text-color;

$secondary-nav-tabs-color: $secondary-nav-text-color;
$secondary-nav-tabs-active-bg: $primary-blue;
$secondary-nav-tabs-active-color: $primary-blue-500;
$secondary-nav-tabs-arrow-color: $primary-blue;

$secondary-nav-tabs-active-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
$secondary-nav-box-shadow: inset 0px 4px 4px 0px rgba(0, 0, 0, 0.02);



// File: _advantage-section
$advantage-section-text-color: $black;



// File: _fast-info-section
$fast-info-section-text-color: $black;



// File: _timer
$timer-main-color: $primary-blue;



// File: _reviwers-block
$reviewers-block-reviewer-stars-color: $primary-orange;



// File: _cms-section
$cms-section-icons-color: #495057;



// File: _our-tenders-section
$our-tenders-section-prozoro-logo-bg: $sasha-gray;



// File: _concurents-table
$concurents-table-row-bg: $sasha-gray;
$concurents-table-row-hover-bg: $sasha-gray;

$concurents-table-hyper-col-text-color: $white;

$concurents-mobile-table-col-text-color: $satan-black;
$concurents-mobile-table-hyper-col-bg: $opel-ampera-red-200;



// File: _product-price-table
$product-price-table-hyper-col-text-color: $white;
$product-price-table-hyper-col-bg: $opel-ampera-red-200;
$product-price-table-hyper-col-top-label-text-color: $black;
$product-price-table-row-first-2-and-hover-bg: $gray-300;



// File: _product-price-table-section
$product-price-table-section-bottom-text-color: $black;



// File: _promo-swiper
$promo-swiper-title-color: $primary-blue;
$promo-swiper-text-color: $primary-blue-101;
$promo-swiper-bg: linear-gradient(359.19deg, #7da4e233 0.08%, #7da4e200 0.08%, #89afe033 0.1%, #afd6e933 67.57%, #afd7e933 104.58%);



// File: _big-promo-section
$big-promo-section-bg: linear-gradient(0deg, #7A9FDD 0%, #AFD7E9 100%);
$big-promo-section-main-text-color: $white;
$big-promo-section-text-block-title-color: $primary-blue;
$big-promo-section-benefits-card-bg: $primary-blue-200;
$big-promo-section-person-vr-color: #96BAE7;



// File: _citation-section
$citation-section-bg: $header-default-bg-gradient;
$citation-section-quotes-color: $primary-blue-200;
$citation-section-title-color: $primary-blue;
$citation-section-citation-card-bg: $primary-blue-200;
$citation-section-citation-card-text-color: $white;

/*                       Includes Components End            */

/*                       Partials Components Start            */

// _elips
$elips-icon-color: $white;



// _pop-ups
$pop-ups-discount-color: #4888A4;
$pop-ups-promo-color: #0A4156;

$pop-ups-close-btn-text-color: $black;
$pop-ups-close-btn-text-shadow-color: $white;

$pop-ups-modal-button-text-color: #282828;
$pop-ups-modal-button-border-color: #F6BF37;
$pop-ups-modal-button-bg: #F6BF37;

$pop-ups-modal-content-bg: $white;
$pop-ups-modal-content-logo-color: $white;

$pop-ups-modal-1-question-text-color: $primary-blue;
$pop-ups-modal-1-list-text-color: $primary-blue;

$pop-ups-modal-2-question-text-color: $white;
$pop-ups-modal-2-list-text-color: $white;

$pop-ups-modal-3-question-text-color: $white;
$pop-ups-modal-3-list-text-color: $white;



// _extra-service-card
$extra-service-bg-color: $gray-100;
$extra-service-circle-color: $primary-blue-light;
$extra-service-circle-hover-color: $primary-blue;
$extra-service-text-color: $primary-blue;
$extra-service-text-hover-color: $white;



// _labels
$label-text-color: $white;
$label-bg-primary: $blue;
$label-bg-hot: $opel-ampera-red;
$label-bg-sale: $primary-orange;



// _progress-bars
$progress-bar-bg: $gray-500;
$progress-bar-color: $primary-blue-202;
$progress-bar-text-color: $primary-black;



// _page-promo-card
$page-promo-card-bg: $white;
$page-promo-price-color: $gray-600;
$page-promo-promo-card-footer-icon-color: $opel-ampera-red;
$page-promo-promo-card-footer-btn-text-color: $opel-ampera-red;
$page-promo-promo-card-footer-btn-text-active-color: $white;

// _tool-promo-card
$tool-promo-card-icon-bg: $sasha-gray;
$tool-promo-card-icon-bg-hover: $primary-blue-500;
$tool-promo-card-icon-color: $satan-black;
$tool-promo-card-icon-color-hover: $black;

// _review-card
$review-card-bg: $white;
$review-card-stars-wrapper-bg: $sasha-gray;
$review-card-rating-from-text-color: $primary-blue;
$review-card-rating-starts-color: $primary-blue;



// _product-promo-card
$product-promo-card-bg: $white;
$product-promo-card-benefits-text-color: $cards-gray;
$product-promo-card-benefits-arrow-color: $red;
$product-promo-card-price-color: $black;



// _more-card
$more-card-bg: $white;
$more-card-icon-color: $primary-blue;



// _table-tabs
$table-tabs-block-bg-color: $gray-100;
$table-tabs-block-shadow: inset 0px 2px 2px #0000000d;
$table-tabs-bg-color: $primary-white;
$table-tabs-bg-active-color: $primary-blue;
$table-tabs-text-color: $primary-black;
$table-tabs-text-active-color: $primary-white;
$table-tabs-shadow: 0px 2px 2px #0000000d;





// custom-forms, custom-select
$custom-select-title-text: $satan-black;
$options-list-bg: $white;
$options-list-border: 1px solid $gray-700;
$option-selected-text: $satan-black;
$option-unselected-text: $gray-700;

$modal-input-before-icon-color: $satan-black;
$modal-input-after-icon-color: $gray-800;
$modal-input: $satan-black;
$modal-input-placeholder: $gray-700;



// _cards
$card-shadow: #09090924;
$card-shadow-hovered: #365b9599;
$hr-gray: $gray-600;
$cards-black: $black;
$card-arrow-color: $gray-800;
$card-arrow-color-hover: $opel-ampera-red-light;



// _tooltips
$tooltip-bg: $white;

$tooltip-text: $satan-black;

$tooltip-box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);

$tooltip-icon: $white;
$tooltip-icon-bg: (
    'info': $primary-blue-200,
    'question': $primary-blue-100,
    'error': $opel-ampera-red-200
);



// _domain-search-form
$domain-search-from-input-text-color: $satan-black;
$domain-search-from-input-placeholder-color: $gray-700;



// _breadcrumbs
$breadcrumbs-text-color: $primary-black;



// _person
$person-name-color: $primary-blue;
$person-speciality-color: $white;
$person-vr-color: $primary-blue-200;



// _google-review-card
$google-review-card-person-speciality-color: $primary-orange;
$google-review-card-person-rating-color: $primary-orange;
$google-review-card-person-review-time-color: #999999;



// _tenders-table
$tenders-table-col-text-color: $satan-black;
$tenders-table-head-col-bg: $gray-300;
$tenders-table-body-col-bg: $gray-100;
$tenders-table-footer-col-text-color: $primary-blue;



// _dropdown-menu
$dropdown-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

$dropdown-ls-icon-color: $satan-black;
$dropdown-ls-icon-border-color: $sasha-gray;
$dropdown-ls-icon-hover-bg: $gray-700;

$dropdown-ls-link-title-color: $primary-black;
$dropdown-ls-link-sub-title-color: $satan-black;
$dropdown-ls-link-hover-bg: $sasha-gray;

$dropdown-rs-icon-color: $satan-black;
$dropdown-rs-title-color: $primary-black;
$dropdown-rs-text-color: $primary-black;
$dropdown-rs-sub-text-color: $primary-black;

$custom-dropdown-text-color: $white;

$nav-services-link-hover-bg: $dropdown-ls-link-hover-bg;
$nav-services-icon-hover-bg: $dropdown-ls-icon-hover-bg;
$nav-services-icon-border-color: $dropdown-ls-icon-border-color;

// _vpn-speed-test-section
$vpn-speed-test-section-vs-color: $primary-blue;



// _contact-from (contact-form)
$contact-form-label-color: $primary-blue;
$contact-form-control-placeholder-color: $gray-700;
$contact-form-section-bg: $sasha-gray;
$contact-form-section-desc-block-text-color: $primary-black;



// _scroll-btn
$scroll-btn-main-color: $primary-blue-light;
$scroll-btn-secondary-color: $gray-100;


/*                       Partials Components End            */

// --------------------- Components End --------------------- //

/* --------------------- Pages Style Start --------------------- */

// core.scss
$main-bg-gray: $sasha-gray;

// // domain-inside
// $domain-page-concurents-table-row-bg: $sasha-gray;
// $domain-page-concurents-table-hyper-col-text-color: $concurents-table-hyper-col-text-color;



// domains
$domain-page-content-domain-selection-block-bg: $primary-blue;
$domain-page-content-domain-selection-block-label-color: $white;



// feedback
$feedback-card-bg: $white;
$feedback-card-check-reviews-text-color: $satan-black;



// site-transfer
$site-transfer-move-to-hyper-text-color: #ffffff42;
$site-transfer-move-to-hyper-stroke-color: 1px #ffffff85;



// vacancies
$vacancies-upload-file-btn-color: $blue;



// wiki
$wiki-search-block-label: $under-header-section-domain-search-form-block-label;
$wiki-search-block-input-text-color: $domain-search-from-input-text-color;
$wiki-search-block-input-placeholder-text-color: $domain-search-from-input-placeholder-color;

/*  --------------------- Pages Style End --------------------- */

/*  --------------------- Gradient Presets Style Start --------------------- */

$gradient-blue: linear-gradient(91deg, #A6C3F1 20.61%, #CEE6FF 108.95%);
$gradient-azure: linear-gradient(91deg, #AFD5EF 20.61%, #C5DCFF 108.95%);
$gradient-aqua: linear-gradient(91deg, #8FE0C8 -9.48%, #CEE6FF 120.21%);
$gradient-purple: linear-gradient(91deg, #9F9DFF 10.96%, #E2CBFA 67.66%);
$gradient-purple-lg: linear-gradient(92deg, #9F9DFF 3.74%, #E2CBFA 107.71%);
$gradient-purple-animated: linear-gradient(95deg, #7BB3E0, #847BE0, #7B93E0, #89D5E1, #A57BE0);


/*  ---------------------  Gradient Presets Style End --------------------- */
