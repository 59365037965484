@use '../custom-variables/colors' as *;
@use '../custom-variables/mixins' as mix;
@use '../custom-variables/grid';

.review-card {
  background: $review-card-bg;
  box-shadow: -5.7px 4.1px 27px rgba(0, 0, 0, 0.08);
  border-radius: 20px;

  padding-top: 0;
  padding-bottom: 27px;
  padding-left: 31px;
  padding-right: 29px;

  max-width: 650px;
  min-height: 350px;

  $text-line-height: 23px;
  $block-max-height: 92px;

  @media (max-width: grid.get-grid-bp('md')) {
    max-height: unset;
    min-width: unset;
    max-width: 345px;
  }

  @media (max-width: grid.get-grid-bp('sm')) {
    padding-left: unset;
    padding-right: unset;

    padding-inline: 24px;
    padding-bottom: 34px;
  }

  .card-header {
    display: flex;

    margin-bottom: 30px;

    @media (max-width: grid.get-grid-bp('md')) {
      flex-direction: column-reverse;
      margin-bottom: 36px;
    }

    .review-info {
      @media (max-width: grid.get-grid-bp('md')) {
        margin-top: 29px;
      }

      .review-comp-logo {
        max-width: 140px;
        max-height: 40px;

        margin-top: 29px;
        margin-bottom: 23px;

        @media (max-width: grid.get-grid-bp('md')) {
          display: none;
        }
      }

      .reviewer-info {
        margin-bottom: 8px;

        .name {
          font-weight: 600;
          font-size: 24px;
          line-height: 36px;


          margin-bottom: 3px;

          @media (max-width: grid.get-grid-bp('md')) {
            margin-bottom: 20px;
          }
        }

        .product-info {
          display: flex;
          align-items: center;


          font-weight: 400;
          font-size: 18px;
          line-height: 20px;

          .plan,
          .domain-name {
            *:last-child {
              margin-left: 5px;
            }
          }

          .domain-name {
            margin-left: 20px;
          }
        }
      }
    }

    .review-rating {
      margin-left: auto;

      @media (max-width: grid.get-grid-bp('md')) {
        margin-left: 0;
        width: 60%;
        align-self: center;
        min-width: 217px;
      }

      .review-stars-wraper {
        background: $review-card-stars-wrapper-bg;

        border-radius: 0px 0px 20px 20px;

        padding: 30px 33px 20px 32px;

        text-align: center;

        .title {
          font-weight: 400;
          font-size: 18px;
          line-height: 21px;

          margin-bottom: 7px;
        }

        .rating {
          .from {
            color: $review-card-rating-from-text-color;
          }

          .to,
          .from {
            font-size: 40px;
          }

          font-weight: 400;
          font-size: 18px;
          line-height: 27px;


          margin-bottom: 7px;

          @media (max-width: grid.get-grid-bp('md')) {
            margin-bottom: 0;
          }
        }

        .rating-stars {
          font-size: 18px;
          color: $review-card-rating-starts-color;

          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .card-text {
    max-width: 584px;

    .review-theme {
      font-weight: 400;
      font-size: 19px;
      line-height: 21px;

      margin-bottom: 9px;

      @media (max-width: grid.get-grid-bp('md')) {
        margin-bottom: 24px;
      }
    }

    .review-text {
      font-weight: 400;
      font-size: 19px;
      line-height: $text-line-height;

    }
  }

  .card-footer {
    display: flex;
    align-items: center;
    justify-content: end;

    @media (max-width: grid.get-grid-bp('md')) {
      display: block;

      margin-top: 15px;
      min-height: 50px;
    }

    .review-comp-logo {
      display: none;

      float: left;

      @media (max-width: grid.get-grid-bp('md')) {
        display: block;
      }
    }

    .more-btn {
      --arrow-size: 35px;
      cursor: pointer;

      display: block;
      float: right;

      transition: transform 0.2s ease-in-out;

      &.active {
        transform: rotate(-180deg);
      }

      &::before {
        @include mix.card-more-btn;
      }
    }
  }

  &.overflow {
    .card-text {
      .review-text {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .card-footer {
      .more-btn {
        transform: rotate(0);
      }
    }
  }
}
