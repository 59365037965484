@use '../custom-variables/colors' as *;
@use '../custom-variables/grid';

.product-table-responsive {
  @media (max-width: grid.get-grid-bp('xl')) {
    overflow-x: scroll;
    overflow-y: visible;
    padding-block: 40px;
  }
}

.product-price-table {
  display: flex;
  flex-direction: column;

  width: 100%;

  @media (max-width: grid.get-grid-bp('lg')) {
    width: max-content;
  }

  background: #fbfbfb;
  border-radius: 10px;

  .table-head {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .table-row {
      padding-top: 11px;

      .table-col {

        >*,
        & {
          font-weight: 400;
          font-size: 25px;
          line-height: 32px;
        }

        &.hyper {
          &::after {
            background: url('/img/bg/concurents-table-col-bg-top.svg') no-repeat;
            background-size: contain;
            top: -45%;
          }

          &::before {
            content: attr(data-translation);

            display: block;
            position: absolute;

            color: $product-price-table-hyper-col-top-label-text-color;

            top: -50px;

            font-weight: 400;
            font-size: 20px;
            line-height: 27px;
          }
        }
      }
    }
  }

  .table-body {
    .table-row {
      .table-col {

        &,
        >* {
          font-weight: 600;
        }
      }

      &:nth-child(1) {
        padding-top: 20px;
        padding-bottom: 6px;

        background: $product-price-table-row-first-2-and-hover-bg;

        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        .table-col {
          &:first-child {
            .row-title {
              font-weight: 400;
              font-size: 25px;
              line-height: 32px;
            }
          }

          .price-block .price,
          .price-block .watermark {
            font-weight: 400;
            font-size: 25px;
            line-height: 32px;
          }

          &.hyper {
            &::after {
              min-height: 150%;
            }
          }
        }
      }

      &:nth-child(2) {
        padding-top: 12px;
        padding-bottom: 29px;

        background: $product-price-table-row-first-2-and-hover-bg;

        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        .table-col {
          &:first-child {
            .row-title {
              .title {
                font-weight: 400;
                font-size: 25px;
                line-height: 32px;
              }

              .sub-title {
                font-weight: 400;
                font-size: 18px;
                line-height: 23px;
              }
            }
          }

          .price-block {
            font-weight: 600;

            .price {
              font-size: 40px;
              line-height: 52px;
            }

            .watermark {
              font-size: 30px;
              line-height: 39px;
            }
          }

          &.hyper {
            &::after {
              min-height: 260%;
            }
          }
        }
      }

      &:nth-child(3) {
        margin-top: 22px;
      }

      &:not(:nth-child(1), :nth-child(2), :nth-child(3)) {
        .table-col {
          .watermark {
            font-weight: 400;
          }
        }
      }

      &:hover {
        background: $product-price-table-row-first-2-and-hover-bg;
      }
    }
  }

  .table-footer {
    background: #fbfbfb;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .table-row {
      padding-top: 8px;
      padding-bottom: 20px;

      .table-col {
        flex-direction: column;

        &.show-all-params {
          flex-direction: row;
          cursor: pointer;

          --arrow-size: 25px;

          &::before {
            content: "";

            display: block;
            float: right;

            width: var(--arrow-size);
            height: var(--arrow-size);

            transition: transform 0.2s ease-in-out;

            margin-right: 15px;

            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23090909'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: center;
            background-size: var(--arrow-size);
          }
        }

        .check {
          font-weight: 400;
          font-size: 16px;
          line-height: 21px;

          margin-top: 4px;
          text-decoration: underline;
          text-underline-offset: 4px;

          background: unset;
          outline: unset;
          border: unset;
          padding: 0;
        }

        &.hyper {
          &::after {
            min-height: 190%;
            top: -20%;

            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
          }

          .check {
            color: $product-price-table-hyper-col-text-color;
          }
        }

        .order {
          min-width: 160px;

          .button {
            width: 100%;
          }
        }
      }
    }
  }

  .table-row {
    display: flex;
    align-items: center;

    padding-inline: 20px;

    .table-col {
      &:first-child {
        text-align: start;

        justify-content: start;

        min-width: 180px;
      }
    }
  }

  .table-col {
    flex: 1;
    text-align: center;

    min-height: 40px;
    min-width: 180px;

    display: flex;
    align-items: center;
    justify-content: center;

    .info-label {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 14px;
      height: 14px;

      min-width: 14px;
      min-height: 14px;

      >i {
        font-size: 9px;
      }
    }

    .hypericon-infinity {
      font-size: 12px;
    }

    &:first-child {
      .row-title {

        >*,
        &,
        .title {
          font-weight: 400;
          font-size: 18px;
          line-height: 23px;
        }

        .sub-title {
          margin-top: 2px;

          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
        }
      }
    }

    >*,
    & {
      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
    }

    &.hyper {
      position: relative;

      color: $product-price-table-hyper-col-text-color;

      min-width: 240px;

      >* {
        position: relative;
        z-index: 2;
      }

      &::after {
        content: '';

        display: block;

        z-index: 1;
        position: absolute;

        min-height: 70px;
        min-width: 180px;

        width: 100%;
        height: 100%;

        top: -50%;

        background: $product-price-table-hyper-col-bg;
        background-size: contain;
      }
    }

    .hypericons.hypericon-check-solid {
      font-size: 14px;
    }
  }

  &.full-size {
    $footer-height: 101px;
    $head-height: 51px;

    $body-first-row-height: 66px;
    $body-second-row-height: 98px;

    .table-head {
      position: sticky;
      top: 0;
      z-index: 1021;
      margin-bottom: $footer-height;

      .table-row {
        background: #FBFBFB;
      }
    }

    .table-body {
      margin-top: calc(-1 * $footer-height);
      margin-bottom: calc(-1 * calc($head-height + $body-first-row-height + $body-second-row-height));

      .table-row {
        &:nth-child(-n+2) {
          position: sticky;
          z-index: 1020;
        }

        &:nth-child(1) {
          top: $head-height;
          margin-bottom: $body-second-row-height;
        }

        &:nth-child(2) {
          top: calc($head-height + $body-first-row-height); // Header + 1-st row height
          margin-top: calc(-1 * $body-second-row-height);
        }
      }
    }

    .table-footer {
      margin-top: calc($head-height + $body-first-row-height + $body-second-row-height);
      position: sticky;
      bottom: 0;
      z-index: 1020;

      .table-row {
        background: #FBFBFB;

        .table-col {
          &:first-child {
            &::before {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
}
