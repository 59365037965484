@use '../custom-variables/colors' as *;
@use '../custom-variables/grid';

.action-section {
  .action-block {
    border-radius: 20px;
    min-height: 300px;

    .action-header {
      >* {
        margin: 0;
      }
    }

    .action-text {
      font-size: 20px;
      font-weight: 400;
      line-height: 1.2;

      >* {
        font: inherit;
        color: inherit
      }
    }

    @import './action-bg';
  }

  &.with-image {
    .action-block {
      display: flex;
    }

    .action-image {
      align-self: center;

      @media (max-width: grid.get-grid-bp('lg')) {
        display: none;
      }
    }

    .content-block {
      display: flex;
      flex-direction: column;
      align-items: start;

      margin-left: 4.33333333%;

      padding-top: 65px;
      padding-bottom: 40px;

      max-width: 848px;

      @media (max-width: grid.get-grid-bp('lg')) {
        align-items: center;
        text-align: center;

        margin-left: 0;

        padding-inline: 10%;

        max-width: unset;
      }

      @media (max-width: grid.get-grid-bp('md')) {
        padding-inline: 5%;
        padding-top: 40px;
      }
    }

    .action-header {
      margin-bottom: 20px;
    }

    .action-text {
      margin-bottom: 1.5rem;

      >* {
        font: inherit;
        margin: 0;
      }

      a {
        color: inherit;
      }
    }

    .action-button {
      &.with-icon {
        .button {
          display: flex;
          align-items: center;

          i {
            margin-left: 0.3rem;
            font-size: 29px;
          }
        }
      }
    }
  }

  &.with-text {
    .action-block {
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: grid.get-grid-bp('lg')) {
        padding: 1rem 1.5rem;
      }

      @media (max-width: grid.get-grid-bp('md')) {
        padding: 1rem 1.5rem;

        height: auto;
      }
    }

    .content-block {
      display: flex;
      flex-direction: column;
      align-items: center;

      text-align: center;
    }

    .action-header {
      color: $action-block-with-text-type-header-text-color;

      margin-bottom: 16px;
    }

    .action-text {
      color: $action-block-with-text-type-main-text-color;

      margin-bottom: 32px;

      letter-spacing: -0.88px;
      line-height: 1.5;

      * {
        margin: 0;
        font: inherit;
        color: inherit;
      }
    }

    .action-button {}
  }

  &.with-buttons {
    .content-block {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      height: inherit;

      padding-block: 50px;
    }

    .btn-title,
    .action-header {
      color: $action-block-with-buttons-type-header-text-color;
    }

    .action-header {
      text-align: center;
    }

    .buttons-block {
      display: flex;
      justify-content: space-between;

      @media (max-width: grid.get-grid-bp('md')) {
        flex-direction: column;
        justify-content: unset;
      }
    }

    .action-button {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (max-width: grid.get-grid-bp('md')) {
        &:first-child {
          margin-block: 40px;
        }
      }
    }

    .btn-title {
      font-size: 20px;
      font-weight: 400;
      line-height: 1.3;
      letter-spacing: -0.88px;

      margin-bottom: 28px;

      @media (max-width: grid.get-grid-bp('md')) {
        &:first-child {
          margin-bottom: 15px;
        }
      }
    }

    .vr {
      width: 2px;
      background-color: $action-block-with-buttons-type-vr-color;
      opacity: 0.3;

      @media (max-width: grid.get-grid-bp('md')) {
        display: none;
      }
    }
  }
}
