@use '../custom-variables/colors' as *;

.label {
  background: $label-bg-primary;
  color: $label-text-color;
  border-radius: 30px;

  margin: 0;

  text-align: center;

  padding-inline: 10px;
  padding-block: 1px;

  font-weight: 400;
  font-size: 16px;
  line-height: 25px;


  &.primary {
    background: $label-bg-primary;
  }

  &.hot {
    background: $label-bg-hot;
  }

  &.sale {
    background: $label-bg-sale;
  }

  &.square {
    border-radius: 3px;

    font-weight: 400;
    font-size: 10px;
    line-height: 11px;

    padding: 5px;
    height: 17px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &[data-bs-toggle="tooltip"] {
    cursor: pointer;
  }
}
