&.action-bg {
  &-1 {
    background: url('/img/bg/action-bg-rows.svg') no-repeat, linear-gradient(0deg, rgba(125, 164, 226, 0.50) 0%, rgba(125, 164, 226, 0.00) 0%, rgba(137, 175, 224, 0.50) 0%, rgba(175, 214, 233, 0.50) 70.31%, rgba(175, 215, 233, 0.50) 100%);

    height: 300px;
  }

  &-2 {
    background: url('/img/bg/small-promo-bg-alt.svg') no-repeat, linear-gradient(359deg, #ADD4E8 0%, rgba(157, 195, 255, 0.53));
    background-size: 30% 300px, 100%;
    background-position: left center;
  }

  &-3 {
    background: url('/img/bg/action-bg-rows-alt.svg') no-repeat, linear-gradient(to top, rgba(137, 175, 224, 0.2) 0%, rgba(175, 214, 233, 0.2) 70%, rgba(175, 215, 233, 0.2) 100%);

    height: 300px;

    @media (max-width: 768px) {
      height: auto;
    }
  }

  &-none {
    background: none;
    height: 349px;
  }
}
