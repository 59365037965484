.promo-code-block,
.text-promo-code-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 0.5px solid #00000033;
  border-radius: 5px;
  padding-inline: 10px;
  min-height: 54px;
  min-width: 154px;

  .promo-code {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.3;
  }

  .promo-code-label {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.2;
  }
}

.promo-code-block {

  position: relative;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;

    background: url('/img/copy-btn.svg') no-repeat;
    background-position: center;
    background-size: 100%;
    width: 10px;
    height: 20px;

    top: 5px;
    right: 5px;
  }
}
