@use '../custom-variables/grid';

.big-benefit-section {
  background: url('/img/bg/waves-grid.svg') no-repeat, linear-gradient(rgba(197, 220, 255, 1), rgba(175, 213, 239, 1));
  background-size: contain;
  background-position: bottom;

  border-radius: 30px;

  padding-top: 80px;
  padding-bottom: 60px;

  min-height: 585px;

  .benefit {
    max-width: 650px;

    .benefit-title {
      display: flex;
      align-items: center;

      margin-bottom: 35px;

      i {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 35px;
        height: 35px;

        font-size: 28px;
      }

      h3 {
        margin-bottom: 0;
        margin-left: 12px;
      }
    }

    .benefit-text {
      .benefits-list {
        .benefit-item {
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
          
        }
      }
    }
  }

  .benefit-col {
    &:nth-child(2) {
      .benefit:last-child {
        margin-top: 50px;
      }
    }
  }

  @media (max-width: grid.get-grid-bp('lg')) {
    .benefit-col {
    //   .benefit {
    //     margin-left: 20%;
    //   }

      &:nth-child(2) {
        .benefit:last-child {
          margin-top: 20px;
        }
      }
    }
  }

  @media (max-width: grid.get-grid-bp('md')) {
    padding-top: 50px;
    padding-bottom: 30px;

    .benefit {
      .benefit-title {
        margin-bottom: 15px;
      }
    }
  }

  @media (max-width: grid.get-grid-bp('sm')) {
    .benefit-col {
    //   .benefit {
    //     margin-left: 0;
    //   }

      &:nth-child(2) {
        .benefit:last-child {
          margin-top: 10px;
        }
      }
    }
  }

  @media (max-width: 375px) {
    .benefit {
      .benefit-title {
        margin-bottom: 5px;
      }
    }
  }
}
