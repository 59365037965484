@use '../custom-variables/grid';

.feedbacks-section {
  background: url('/img/bg/feedback-section.svg') no-repeat;
  background-size: cover;

  padding-top: 9vw;
  padding-bottom: 33px;

  .feedback-header {
    * {
      //   font-weight: 600;
      //   font-size: 48px;
      //   line-height: 55px;
      //   letter-spacing: -0.88px;

      margin: 0;
    }

    text-align: center;
  }

  .feedback-logos {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    margin-top: 4vw;
    margin-bottom: 5.2vw;
  }

  .feedback-logo-row {
    display: flex;

    flex-wrap: wrap;
    gap: 72px 0px;

    width: 100%;
    max-height: 206px;

    @media (max-width: grid.get-grid-bp('lg')) {
      gap: 40px 0px;
      max-height: unset;
    }
  }

  .feedback-logo {
    display: flex;
    align-items: center;
    justify-content: center;

    flex: 0 0 auto;
    width: 20%;

    @media (max-width: grid.get-grid-bp('lg')) {
      width: 50%;
    }

    img {
      min-width: 9.4vw;

      max-width: 15vw;

      mix-blend-mode: multiply;
    }
  }

  .feedback-footer {
    text-align: center;

    font-weight: 400;
    font-size: 25px;
    line-height: 40px;
    


    * {
      margin: 0;
    }

    a {
      color: unset;
    }
  }

  @media (max-width: grid.get-grid-bp('lg')) {
    background: unset;
  }
}
