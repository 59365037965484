@use '../custom-variables/colors' as *;
@use '../custom-variables/grid';

.site-speed-test-section {
  background: url('/img/bg/vpn-speed-test-bg.svg') no-repeat;
  //   background: $header-default-bg-gradient;
  background-size: cover;

  border-radius: 20px;

  padding-top: calc(62 / 16) + rem;
  padding-bottom: calc(70 / 16) + rem;

  .header {
    text-align: center;
    margin-bottom: 3.5rem;

    >* {
      margin: 0;
    }
  }

  .versus-block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .vs {
      color: $vpn-speed-test-section-vs-color;

      font-weight: 600;
      font-size: 45px;
      line-height: 55px;
      letter-spacing: -0.88px;
    }
  }

  .res-block {
    display: flex;
    flex-direction: column;
    align-items: center;

    .img-block {
      max-width: 501px;

      .chart-block {
        width: 340px;
      }
    }

    .text {
      font-weight: 600;
      line-height: 33px;
      letter-spacing: -0.88px;

      margin-top: 1rem;
    }
  }

  .sub-text {
    text-align: center;
    color: $primary-black;

    font-size: 14px;
    line-height: 1.2;

    margin-top: 2rem;
  }

  @media (max-width: grid.get-grid-bp('xl')) {
    .versus-block {
      padding-inline: 41px;
    }
  }

  @media (max-width: grid.get-grid-bp('lg')) {
    .header {
      margin-bottom: 2rem;
    }

    .versus-block {
      flex-direction: column;
      justify-content: unset;

      .vs {
        margin-block: 0.6rem;
        text-align: center;
      }
    }
  }
}
