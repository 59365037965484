@use 'colors';
@use 'custom-select';

.form-check-input {
  width: 16px;
  height: 16px;

  background-color: colors.$white;
  border: 1px solid colors.$black;

  margin-top: 0.35em;

  &:checked {
    background-color: unset;
    border-color: unset;
  }

  &[type=radio] {
    border-radius: 50%;
    border-color: colors.$black;

    &:checked {
      background-image: url("data: image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8' ><circle r='2' fill='%23000' /></svg>");
    }
  }
}

.custom-input-group {
  display: flex;
  align-items: center;
  justify-content: center;

  .custom-input-placeholder {

    &.before,
    &.after {
      border: 1px solid black;
      border-radius: 30px;

      padding: 9px 0;

      width: 100%;

      max-width: 35px;
      max-height: 47px;

      @media (max-width:500px) {
        max-height: 35px;
        padding: 3px 0;
      }
    }

    &.before {
      color: colors.$modal-input-before-icon-color;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-right: 0;

      & * {
        margin-left: 16px;
      }
    }

    &.after {
      color: colors.$modal-input-after-icon-color;
      cursor: pointer;

      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      border-left: 0;

      & * {
        margin-right: 19px;
      }
    }
  }

  &.with-before {

    .custom-input,
    .custom-select {
      border-left: unset !important;

      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      padding-left: 5px;
    }
  }

  &.with-after {

    .custom-input,
    .custom-select {
      border-right: unset !important;

      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      padding-right: 5px;
    }
  }

  .custom-input {
    max-width: 326px;
    max-height: 47px;
    width: 100%;

    border: 1px solid black;

    border-radius: 30px;

    color: colors.$modal-input;

    outline: unset;

    padding: 11.5px 20px;

    font-weight: 400;
    font-size: 20px;
    line-height: 22px;

    &::placeholder {
      color: colors.$modal-input-placeholder;

      font-size: 16px;
    }

    @media (max-width:500px) {
      max-height: 35px;
    }
  }
}

.custom-from-control {
  border: 1px solid #000000;
  border-radius: 30px;

  padding-inline: 35px;
  padding-block: 11px;

  &:focus {
    border-color: #9badca;
  }
}
