@use '../custom-variables/colors' as *;

.person {
  display: flex;
  align-items: center;

  .vr {
    background-color: $person-vr-color;
    width: 5px;
    border-radius: 5px;
    opacity: unset;
  }

  .person-info {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    align-self: start;

    padding-left: 13px;

    .name {
      color: $person-name-color;

      font-weight: 600;
      font-size: 20px;
      line-height: 20px;

    }

    .speciality {
      color: $person-speciality-color;

      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
    }
  }
}
