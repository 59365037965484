@use '../custom-variables/colors' as *;
@use '../custom-variables/grid';

.benefits-card {
  background: url('/img/bg/grids/grid-2.svg') no-repeat, $header-default-bg-gradient;
  background-size: cover;

  border-radius: 20px;
  min-height: 585px;

  padding-top: 65px;
  padding-right: 72px;
  padding-left: 49px;

  .title-block {
    display: flex;
    align-items: center;

    margin-bottom: 3.3rem;

    .icon {
      font-size: 35px;
      margin-right: 20px;
    }

    .title {
      font-weight: 600;
      line-height: 1.3;

      margin: 0;
    }
  }

  .text-block {

    >p,
    & {
      font-size: 20px;
      font-weight: 400;
      line-height: 1.3;
    }

    p {
      margin: 0;
      padding-block: 1rem;
    }
  }

  &.bg-part-1 {
    background-position: 1rem 0px, center;
  }

  &.bg-part-2 {
    background-position: -30rem 0px, center;
  }

  &.bg-part-3 {
    background-position: -60rem 0px, center;
  }

  @media (max-width: grid.get-grid-bp('xl')) {
    .title-block {
      margin-bottom: 1rem;
    }
  }

  @media (max-width: grid.get-grid-bp('lg')) {
    min-height: 400px;
  }

  @media (max-width: grid.get-grid-bp('md')) {
    .title-block {
      margin-bottom: 0.5rem;
    }
  }

  @media (max-width: grid.get-grid-bp('sm')) {
    padding-top: 2rem;
    padding-inline: 1rem;

    .title-block {
      .icon {
        font-size: 25px;
        margin-right: 10px;
      }
    }

    .text-block {
      p {
        padding-block: 0.5rem;
      }
    }
  }
}

.benefits-card-sm {
  background: url('/img/bg/grids/grid-2.svg') no-repeat, $header-default-bg-gradient;
  background-size: cover;

  min-height: 435px;

  max-width: 405px;
  min-width: 405px;

  border-radius: 20px;

  padding: 53px 39px;

  .title-block {
    display: flex;
    align-items: center;

    margin-bottom: 2.3rem;

    .icon {
      font-size: 28px;
      margin-right: 1rem;
    }

    .title {
      line-height: 1.3;

      margin: 0;
    }
  }

  .text-block {

    >p,
    & {
      font-size: 20px;
      font-weight: 400;
      line-height: 1.3;
    }

    p {
      margin: 0;
    }
  }

  a {
    color: inherit;
  }

  &.bg {
    &-part-1 {
      background-position: 1rem 0px, center;
    }

    &-part-2 {
      background-position: -22rem 0px, center;
    }

    &-part-3 {
      background-position: -45rem 0px, center;
    }

    &-part-4 {
      background-position: -68rem 0px, center;
    }
  }

  @media (max-width: grid.get-grid-bp('sm')) {
    min-width: 300px;

    padding: 2rem 0.7rem;
  }
}
