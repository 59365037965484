@use '../custom-variables/colors' as *;

$border-radius: 5px;

.custom-table table,
table.custom-table {
  border-collapse: separate;
  border-spacing: 0 12px;

  width: 100%;

  min-width: 63rem;

//   margin-bottom: 110px;

  thead {
    tr {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;


      color: $table-head-text;

      td,
      th {
        background: $table-head-bg;

        &:first-child {
          border-top-left-radius: $border-radius;
          border-bottom-left-radius: $border-radius;

          padding-left: 26px;

          .header-text {
            justify-content: start;
          }
        }

        padding-block: 12px;

        .header-text {
          display: flex;
          align-items: center;
          justify-content: center;

          .arrow-icons {
            display: flex;
            align-items: center;

            margin-left: 4px;
          }
        }

        &:last-child {
          border-top-right-radius: $border-radius;
          border-bottom-right-radius: $border-radius;
        }
      }
    }
  }

  tbody {
    tr {
      font-weight: 400;
      font-size: 20px;
      line-height: 25px;


      color: $table-row-text;

      &:hover {

        td:not(.vds-first-cell, .vds-cell),
        th {
          background: $table-row-bg-hover;
        }
      }

      td,
      th {
        background: $table-row-bg;

        &:first-child {
          border-top-left-radius: $border-radius;
          border-bottom-left-radius: $border-radius;

          text-align: start;
          padding-left: 26px;

          .info {
            display: flex;
            align-items: center;

            .labels {
              display: flex;
              align-items: center;

              justify-content: space-around;

              margin-left: 9px;

              width: 90px;
            }
          }
        }

        padding-block: 21.5px;
        text-align: center;

        &.available {
          color: $table-row-available;
        }

        &.not-available {
          color: $table-row-not-available;
        }

        &:last-child {
          border-top-right-radius: $border-radius;
          border-bottom-right-radius: $border-radius;
        }

        *:not(.button, .label) {
          color: $satan-black;
        }

        &.with-old {
          .old {
            .domain-price, .price-block {
              color: $table-row-old-price;

              * {
                color: $table-row-old-price;
              }

              text-decoration: line-through;
              font-size: 17px;
            }
          }
        }
      }
    }
  }

  .vds-first-cell {
    background: url("/img/vds-rows-decoration-titled.svg") no-repeat;
    background-size: 10%;
    border-left: $table-row-border-for-promouted-vds solid 1px;
    border-top: $table-row-border-for-promouted-vds solid 1px;
    border-bottom: $table-row-border-for-promouted-vds solid 1px;
    background-position-x: -1px;
    background-position-y: 50%;
    background-color: rgba(248, 250, 253, 0.5019607843);

    .info {
      margin-left: 1.5em;
    }
  }

  .vds-cell {
    border-top: $table-row-border-for-promouted-vds solid 1px;
    border-bottom: $table-row-border-for-promouted-vds solid 1px;
  }

  .vds-cell-last {
    border-top: $table-row-border-for-promouted-vds solid 1px;
    border-bottom: $table-row-border-for-promouted-vds solid 1px;
    border-right: $table-row-border-for-promouted-vds solid 1px;
  }

  .fixed-col-width {
    width: 190px;
  }

  .info-label {
    height: 14px;
    width: 14px;
    background-color: #D9D9D9;
    border-radius: 50%;
    font-size: 9px;
  }

  .icon-fix {
    vertical-align: 50%;
  }
}

th {
  font-weight: inherit;
}
