@use '../custom-variables/colors' as *;
@use '../custom-variables/grid';

.pages-promo-section {
  padding: 0;

  .promo-title-block {
    padding-top: 7rem;

    color: $pages-promo-section-title-color;

    text-align: center;

    .title {

      &,
      >* {
        font-weight: 600;
        // font-size: 40px;
        line-height: 1.1;
        letter-spacing: -0.88px;

        margin: 0;
      }
    }

    .sub-title {
      margin-top: 1.95rem;

      &,
      >* {
        line-height: 1.5;
      }

      >* {
        margin: 0;
      }
    }
  }

  .promo-cards-block {
    padding-top: 6.5rem;
    padding-bottom: 6rem;
    padding-inline: 0;
  }

  .promo-cards-col {
    display: flex;
    align-items: center;
    justify-content: center;

    &>* {
      &::-webkit-scrollbar {
        display: none;
      }

      /* IE and Edge */
      -ms-overflow-style: none;

      /* Firefox */
      scrollbar-width: none;
    }
  }

  .promo-cards {
    display: flex;

    padding: 1rem 0;

    .page-promo-card,
    .form-card {
      &:not(:first-child) {
        margin-left: 57px;
      }
    }
  }

  .bottom-text {

    *,
    & {
      text-align: center;
      font-weight: 500;
      font-size: 25px;
      line-height: 40px;
    }

    >* {
      margin: 0;
    }

    a {
      color: inherit;
    }
  }

  @media (max-width: grid.get-grid-bp('xl')) {

    .promo-cards,
    .tab-pane {
      overflow-x: scroll;
    }

    .promo-cards {
      padding: 3rem 2.5rem;
    }
  }

  @media (max-width: grid.get-grid-bp('lg')) {
    .promo-cards-block {
      padding-top: 1rem;
      padding-bottom: 2.5rem;
    }

    .promo-cards {

      .page-promo-card,
      .form-card {
        &:not(:first-child) {
          margin-left: 2rem;
        }
      }
    }

    .bottom-text {

      *,
      & {
        font-size: 1rem;
      }
    }
  }

  @media (max-width: grid.get-grid-bp('md')) {

    .page-promo-card,
    .form-card {
      &:not(:first-child) {
        margin-left: 1rem !important;
      }
    }

    .promo-cards-block {
      >* {
        padding-inline: 0;
      }
    }

    .promo-cards {
      padding: 3rem 1rem;
    }
  }
}
