span.price-block,
.price-block>span {
  .prefix {
    margin-right: 6px;
  }

  .price {}

  .watermark {
    margin-left: 6px;
  }

  .sufix {}
}


.inline-sufix-for-price {
  display: inline;
  
  //sufixes in dedicated cards must be inlined
  .sufix {
    display: inherit !important;
  }
}
