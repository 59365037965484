@use '../custom-variables/grid';

.extra-service-section {
  .header {
    margin-bottom: 3em;
  }

  .cards-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    gap: 3rem 5%;
  }

  .extra-service {
    flex: 0 0 auto;
  }

  @media (max-width: grid.get-grid-bp('lg')) {
    .cards-row {
      justify-content: space-between;
    }
  }

  @media (max-width: grid.get-grid-bp('sm')) {
    .cards-row {
      justify-content: center;
      gap: 1rem 30%;
    }
  }
}
