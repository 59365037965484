@use '../custom-variables/colors' as *;
@use '../custom-variables/grid';

.extra-service-card {
  display: flex;
  align-items: center;
  flex-direction: column;

  background: $extra-service-bg-color;

  border-radius: 20px;

  width: 230px;
  height: 250px;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 2rem;

    font-weight: 600;
    font-size: 22px;
    line-height: 1.3;

    width: 90px;
    height: 90px;

    border-radius: 50%;

    background: $extra-service-circle-color;
    color: $extra-service-text-color;

    i {
      font-size: 1.77rem;
    }
  }

  .title {
    color: $extra-service-text-color;

    font-size: 20px;
    font-weight: 600;
    line-height: 1.3;

    max-width: 10rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    margin-top: 20px;

    text-align: center;

    &:is(a) {
      text-decoration: underline;
      text-underline-offset: 4px;
    }
  }

  .card-price {
    margin-top: 1rem;

    font-size: 30px;
    font-weight: 600;
    line-height: 1.3;

    .price-block {

      .watermark,
      .sufix,
      .prefix {
        font-size: 18px;
        font-weight: 400;
        line-height: 1.3;
      }

      .price {
        font-size: 30px;
        font-weight: 600;
        line-height: 1.3;
      }
    }
  }

  &:hover {
    box-shadow: inset 0px 4px 4px #0000001a;

    .icon {
      box-shadow: inset 0px 4px 4px #00000040;
      background: $extra-service-circle-hover-color;
      color: $extra-service-text-hover-color;
    }
  }

  @media (max-width: grid.get-grid-bp('md')) {
    height: 130px;

    .icon {
      display: none;
    }
  }
}
