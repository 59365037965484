@use '../custom-variables/colors' as *;

.product-price-table-section {
  .header {
    text-align: center;

    margin-bottom: 17px;

    .title {
      font-weight: 600;
    //   font-size: 40px;
      line-height: 50px;
      letter-spacing: -0.88px;
      margin: 0;

      margin-bottom: 32px;
    }

    .sub-title {
      font-weight: 300;
      font-size: 24px;
      line-height: 30px;


      margin: 0;
    }
  }

  .product-price-table {
    margin-top: 93px;
  }

  .bottom-text {
    text-align: center;

    margin-top: 72px;

    * {
      font-weight: 400;
      font-size: 24px;
      line-height: 40px;


      margin: 0;
      color: $product-price-table-section-bottom-text-color;
    }
  }
}
