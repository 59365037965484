@use '../custom-variables/colors' as *;
@use '../custom-variables/grid';

.big-promo-section {
  background: $big-promo-section-bg;
  border-radius: 20px;

  &.with-image {
    .promo-row {
      >* {
        padding-top: 60px;
        padding-bottom: 142px;

        @media (max-width: grid.get-grid-bp('lg')) {
          padding-bottom: 4em;
          padding-top: 3em;
        }
      }
    }

    .img-block {
      align-items: end;
    }
  }

  &.with-person {
    .promo-row {
      >* {
        padding-top: 60px;
        padding-bottom: 0;

        @media (max-width: grid.get-grid-bp('xl')) {
          padding-bottom: 50px;
        }
      }
    }

    .text-block {
      padding-bottom: 50px;

      .title {
        margin-bottom: 3.5rem;
      }
    }

    .img-block {
      align-items: end;

      &::before {
        top: unset;
        background-position: bottom;
      }
    }

    .person {
      margin-top: 2.5rem;

      @media (max-width: grid.get-grid-bp('xl')) {
        display: none;
      }

      .vr {
        background-color: $big-promo-section-person-vr-color;
      }
    }
  }

  &.with-page-cards,
  &.with-product-cards {
    background: url('/img/bg/waves-grid.svg') no-repeat, #{$big-promo-section-bg};
    background-position: bottom;

    .promo-row {
      >* {
        padding-top: 50px;
        padding-bottom: 70px;
      }
    }

    .text-block {
      .title {
        margin-bottom: 4rem;
      }
    }

    .cards-block {
      display: flex;
      align-items: center;
      justify-content: space-between;

      padding-top: 110px;
    }
  }

  &.with-product-cards {
    .cards-block {
      padding-block: 80px;
    }
  }

  .promo-benefit-title,
  .promo-benefits .promo-benefit,
  .promo-benefit-undertitle,
  .promo-benefit-undertitle>* {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: -0.88px;

    color: $big-promo-section-main-text-color;
  }

  .promo-benefit-block {
    display: flex;
    flex-direction: column;

    padding-top: 31px;
    padding-inline: 31px;

    min-height: 245px;

    background: $big-promo-section-benefits-card-bg;

    border-radius: 10px;

    margin-bottom: 2.3rem;

    .promo-benefit-title {
      margin-bottom: 1.3rem;
    }

    .promo-benefits {
      .promo-benefit {
        a {
          color: inherit;
        }
      }
    }
  }

  .promo-benefit-undertitle,
  .promo-benefit-block {
    max-width: 545px;
  }

  .promo-benefit-undertitle {
    background: $big-promo-section-benefits-card-bg;

    border-radius: 10px;

    padding-block: 1.4em;

    text-align: center;

    p,
    & {
      margin: 0;
    }

    a {
      color: inherit;
    }
  }

  .promo-cards-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    gap: 59px 44px;

    @media (max-width: grid.get-grid-bp('xl')) {
      justify-content: center;
      gap: 1.5em;
    }

    @media (max-width: grid.get-grid-bp('lg')) {
      align-items: center;
      flex-direction: column;
    }

    .promo-card {
      flex: 1 0 auto;

      background: $big-promo-section-benefits-card-bg;

      color: $big-promo-section-main-text-color;

      border-radius: 10px;

      max-width: 455px;
      min-height: 130px;

      padding-block: 18px;
      padding-inline: 20px;

      font-size: 20px;

      font-weight: 400;
      line-height: 1.3;
      letter-spacing: -0.88px;

      @media (max-width: grid.get-grid-bp('xl')) {
        max-width: 40%;
      }

      @media (max-width: grid.get-grid-bp('lg')) {
        max-width: unset;

        font-size: calc(0.7rem + 1.575vw);
      }
    }
  }

  .img-block {
    display: flex;
    justify-content: center;

    position: relative;

    padding-top: 190px;
    padding-bottom: 144px;

    &::before {
      content: '';
      position: absolute;

      width: 100%;
      height: 100%;

      min-height: 514px;
      max-width: 484px;

      right: 1em;
      top: 4em;

      background: url('/img/bg/people-bg.svg') no-repeat;
      background-size: contain;

      z-index: 0;
    }

    img {
      z-index: 1;
    }
  }

  .text-block {
    .title {
      color: $big-promo-section-text-block-title-color;

      padding-left: 20px;

      margin-bottom: 5em;

      @media (max-width: grid.get-grid-bp('xl')) {
        text-align: center;
        margin-bottom: 2em;
      }

      @media (max-width: grid.get-grid-bp('lg')) {
        padding-left: 0;
      }

      >* {
        margin: 0;
        font-weight: 600;
      }
    }
  }

  @media (max-width: grid.get-grid-bp('xl')) {
    .text-block {
      display: flex;
      flex-direction: column;

      .promo-benefit-block,
      .promo-benefit-undertitle {
        max-width: 545px;
        width: 100%;
      }
    }

    &.with-page-cards,
    &.with-product-cards {

      .promo-row>* {
        padding-top: 64px;
        padding-bottom: 105px;

        padding-left: calc(var(--bs-gutter-x) * 0.5);
      }
    }

    &.with-page-cards {
      min-height: 700px;
    }

    &.with-product-cards {
      min-height: 810px;
    }

    .cards-block {
      position: relative;

      .promo-card {
        position: absolute;

        transition: z-index 0.1s ease-in-out;

        &:first-child {
          top: 90px;

          z-index: 0;

          &:hover {
            z-index: 1;
          }
        }

        &:last-child {
          right: 40px;
          bottom: 0;

          z-index: 0;
        }
      }
    }
  }

  @media (max-width: grid.get-grid-bp('lg')) {

    &.with-page-cards,
    &.with-product-cards {
      .promo-row>* {
        padding-top: 64px;
        padding-bottom: 50px;
      }
    }

    .cards-block {
      overflow-x: scroll;
      overflow-y: visible;

      padding-block: 50px;
      padding-inline: 80px;
      padding-top: 0;

      max-width: 545px;
      margin-inline: auto;

      .promo-card {
        position: unset;

        &:not(:last-child) {
          margin-right: 2rem;
        }
      }
    }

    .with-page-cards {
      .cards-block {
        padding-inline: 50px;
      }
    }
  }

  @media (max-width: grid.get-grid-bp('sm')) {
    .cards-block {
      padding-inline: 25px;

      .promo-card {
        scale: 0.9;

        &:not(:last-child) {
          margin-right: 0;
        }
      }
    }
  }
}
