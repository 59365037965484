@use '../custom-variables/colors' as *;

.scroll-to-top-btn {
  --window-left-padding: 3rem;
  --window-bottom-padding: 1.5rem;
  --btn-size: 50px;

  --progress-circle-stroke-width: 4;
  --progress-circle-bg-stroke-width: 1.5;

  --main-color: #{$scroll-btn-main-color};
  --secondary-color: #{$scroll-btn-secondary-color};

  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;

  bottom: var(--window-bottom-padding);
  left: var(--window-left-padding);

  width: var(--btn-size);
  aspect-ratio: 1/1;

  border-radius: 50%;

  cursor: pointer;

  transition: all 0.1s ease-in-out;

  .icon {
    transform: rotate(-90deg); // rotate() because arrow is looking to right, not to top

    color: var(--main-color);
    font-size: 19px;
  }

  .progress-wrap {
    display: block;
    position: absolute;

    width: var(--btn-size);
    height: var(--btn-size);

    border-radius: 50%;

    pointer-events: none;

    transform: rotate(-90deg);
    transform-origin: calc(var(--btn-size) / 2);

    transition: translate .3s linear;
    z-index: -1;

    -webkit-backface-visibility: hidden;

    .progress-circle {
      stroke: var(--main-color);

      &-bar,
      & {
        fill: transparent;
        stroke-width: var(--progress-circle-stroke-width);
        stroke-dashoffset: 0;
      }

      &-bar {
        stroke-width: var(--progress-circle-bg-stroke-width);
        stroke: var(--secondary-color);
      }
    }
  }

  &:hover {
    .progress-wrap {
      .progress-circle-bar {
        fill: var(--secondary-color);
      }
    }
  }
}
