@use '../custom-variables/colors' as *;
@use '../custom-variables/grid';

.secondary-nav {
  display: flex;
  align-items: center;

  box-shadow: $secondary-nav-box-shadow;

  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.88px;

    color: $secondary-nav-title-color;
  }

  .links-block {
    display: flex;
    align-items: center;

    padding-top: 9px;

    @media (max-width: grid.get-grid-bp('md')) {
      margin-left: 5%;
    }
  }

  .link-block {
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;

    text-decoration: none;

    --link-block-size: 65px;

    width: var(--link-block-size);
    height: var(--link-block-size);

    border-radius: 10px;

    &:not(:last-child) {
      margin-right: 1.6em;

      @media (max-width: grid.get-grid-bp('md')) {
        margin-right: 1em;
      }
    }

    .name {
      font-size: 10px;
      font-weight: 600;
      line-height: 1.3;
      letter-spacing: -0.88px;

      margin-bottom: 3px;

      color: $secondary-nav-tabs-color;
    }

    .icon {
      font-size: 2rem;

      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      >span {
        font-size: 18px;
        font-weight: 600;
        line-height: 15px;
      }
    }

    &.active {
      background: $secondary-nav-tabs-active-bg;

      box-shadow: $secondary-nav-tabs-active-shadow;

      .name,
      .icon {
        color: $secondary-nav-tabs-active-color;
      }

      &::after {
        content: '';
        position: absolute;

        --arrow-size: 12px;
        --arrow-height: 5px;
        --arrow-color: #{$secondary-nav-tabs-arrow-color};

        top: 100%;

        border-top: var(--arrow-height) solid var(--arrow-color);
        border-right: var(--arrow-size) solid transparent;
        border-left: var(--arrow-size) solid transparent;
        border-bottom: 0;
      }
    }
  }
}
