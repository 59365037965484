@use '../custom-variables/colors' as *;
@use '../custom-variables/grid';
@use '../custom-variables/mixins' as mix;

.google-review-card {
  min-height: 350px;

  max-width: 650px;

  border-radius: 20px;
  box-shadow: -5.7px 4.1px 27px rgba(0, 0, 0, 0.08);

  padding: 29px 23px 31px 41px;

  $block-max-height: 90;
  $text-line-height: 30;

  .review {
    &-person-info {
      display: flex;

      .person-logo {
        img {
          border-radius: 50%;
        }
      }

      .commentator {
        display: flex;
        flex-direction: column;
        margin-left: 42px;

        .person {
          &-name {
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;

          }

          &-speciality {
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;


            color: $google-review-card-person-speciality-color;
          }
        }

        .comment-info {
          display: flex;
          align-items: baseline;

          margin-top: 3px;

          .person-rating {
            display: flex;
            margin-right: 10px;

            i {
              color: $google-review-card-person-rating-color;
            }
          }

          .review-time {
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;

            color: $google-review-card-person-review-time-color;
          }
        }
      }
    }

    &-text-block {
      margin-top: 35px;

      height: 100%;
      max-height: unset;

      .review-text {
        transition: 0.2s ease-in-out;

        font-weight: 400;
        font-size: 20px;
        line-height: $text-line-height + px;
      }

      .more-btn {
        --arrow-size: 35px;

        cursor: pointer;
        position: relative;

        margin-top: 30px;

        height: var(--arrow-size);

        &:before {
          @include mix.card-more-btn;

          position: absolute;
          float: unset;
          transform: rotate(-180deg);
          right: 0;
        }
      }
    }
  }

  &.overflow {
    .review-text-block {
      max-height: 153px;

      .review-text {
        display: -webkit-box;
        -webkit-line-clamp: calc($block-max-height / $text-line-height);
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .more-btn {
        &::before {
          transform: rotate(0);
        }
      }
    }
  }

  @media (max-width: grid.get-grid-bp('sm')) {
    .review {
      &-person-info {
        flex-direction: column;
        align-items: center;

        .commentator {
          align-items: center;
          margin-left: unset;

          .comment-info {
            display: flex;
            flex-direction: column;
            align-items: center;

            .person-rating {
              margin-right: 0;
            }

            .review-time {
              margin-top: 10px;
            }
          }
        }
      }

      &-text-block {
        margin-top: 15px;
      }
    }
  }
}
