@use '../custom-variables/colors' as *;

.tooltip {
  padding: 0;

  max-width: 240px;

  min-height: 36px;

  $sides: (
    'top': 'top',
    'bottom': 'bottom',
    'right': 'right',
    'left': 'left',
  );

  &.show {
    opacity: 1;
  }

  @each $side,
  $rule in $sides {
    &[data-popper-placement="#{$side}"] {
      .tooltip-arrow {

        &::before {
          border-#{$rule}-color: map-get($tooltip-icon-bg, 'info') !important;
        }

        @each $tooltipType,
        $color in $tooltip-icon-bg {
          &.#{$tooltipType} {
            &::before {
              border-#{$rule}-color: $color !important;
            }
          }
        }
      }
    }
  }

  .tooltip-text-wrapper {
    display: flex;

    box-shadow: $tooltip-box-shadow;

    // margin-bottom: 7px;

    .tooltip-icon {
      display: flex;
      align-items: center;
      justify-content: center;

      color: $tooltip-icon;
      background: map-get($tooltip-icon-bg, 'info');

      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;

      width: 40px;

      @each $tooltipType,
      $color in $tooltip-icon-bg {
        &.#{$tooltipType} {
          background: $color;
        }
      }
    }

    .tooltip-inner {
      color: $tooltip-text;

      background-color: $tooltip-bg;

      font-family: 'Noto Sans', sans-serif;
      font-weight: 400;
      font-size: 13px;
      line-height: 17px;
      letter-spacing: 0;

      border-radius: 0;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;

      padding: 10px 16px;
    }
  }
}

.info-label {
  width: 1rem;
  height: 1rem;
  font-size: 0.75rem;
  min-width: 1rem;
  min-height: 1rem;
}
