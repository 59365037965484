@use '../custom-variables/colors' as *;
@use '../custom-variables/grid';

.advantage-section {
  background: url('/img/bg/small-promo-bg.svg') no-repeat;
  background-size: 50% 350px;
  background-position: left top;

  min-height: 350px;

  .advantage-block {
    display: flex;
    flex-direction: row;

    .img-block {
      padding-top: 6%;

      min-width: 300px;
      max-width: 340px;

      img {
        max-height: 175px;
        float: right;
      }
    }

    .content-block {
      max-width: 985px;

      .title {
        font-size: 40px;

        h2, .h2 {
          letter-spacing: -0.88px;
          margin: 0;
        }

        margin-bottom: 33px;
      }

      .text-block {

        &,
        >* {
          font-size: 20px;
          font-weight: 400;
          line-height: 1.3;

          margin: 0;

          color: $advantage-section-text-color;
        }

        a {
          color: inherit;
        }
      }

      .btn-block {
        display: flex;
        margin-top: 40px;
      }
    }
  }

  @media (max-width: grid.get-grid-bp('xl')) {
    .advantage-block {
      .content-block {
        margin-left: 10%;
      }
    }
  }

  @media (max-width: grid.get-grid-bp('lg')) {
    background: unset;

    .advantage-block {
      flex-direction: column;
      align-items: center;

      .img-block {
        text-align: center;
        padding-top: 0;
        // margin-bottom: 5rem;

        img {
          float: unset;
          max-width: 100%;
          height: auto;
        }
      }

      .content-block {
        margin-left: 0;

        padding-inline: 10%;

        .title {
          text-align: center;
        }

        .text-block {
          //   text-align: center;
        }

        .btn-block {
          justify-content: center;
        }
      }
    }
  }

  @media (max-width: grid.get-grid-bp('md')) {
    .advantage-block {
      padding: 0;

      .content-block {
        padding-inline: 0;
      }
    }
  }

  .pt-6 {
    padding-top: 6rem;

    @media (max-width: grid.get-grid-bp('lg')) {
      padding-top: 0;
    }
  }

  .pt-5-rem {
    padding-top: 5rem;

    @media (max-width: grid.get-grid-bp('lg')) {
      padding-top: 0;
    }
  }
}
