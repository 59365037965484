@use '../custom-variables/colors' as *;
@use 'cards';

.product-promo-card {
  display: flex;
  flex-direction: column;

  background: $product-promo-card-bg;
  border-radius: 25px;

  min-width: 312px;
  max-width: 312px;

  min-height: 580px;
  max-height: 580px;

  padding-inline: 22px;
  padding-block: 29px;

  @include cards.card-shadow;

  .promo-card {
    &-head {
      @include cards.promo-card-head;
    }

    &-body {
      margin-top: 19px;

      .bars-block {
        .bar {
          margin: 0;

          .bar-name,
          .bar-text {
            font-weight: 600;
          }

          &:not(:first-child) {
            margin-top: 9px;
          }

          .bar-text {
            i {
              font-size: 13px;
            }
          }
        }
      }

      .benefits-block {
        margin-top: 24px;

        .benefits:not(ul) {
          display: flex;
          flex-wrap: wrap;

          .benefit:not(li) {
            &:not(:last-child) {
              margin-right: 8px;
            }

            display: flex;
            align-items: center;

            color: $product-promo-card-benefits-text-color;

            i {
              margin-right: 6px;

              font-size: 19px;

              color: $product-promo-card-benefits-arrow-color;
            }

            p {
              font-weight: 400;
              font-size: 16px;
              line-height: 25px;


              white-space: nowrap;

              margin: 0;
            }
          }
        }
      }
    }

    &-footer {
      display: flex;
      flex-direction: column;
      align-items: center;

      margin-top: auto;

      .price-block {
        color: $product-promo-card-price-color;

        .prefix {
          font-weight: 600;
          font-size: 16px;
          line-height: 21px;

        }

        .price {
          font-weight: 600;
          font-size: 40px;
          line-height: 60px;
          letter-spacing: -0.88px;
        }

        .watermark, .sufix {
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          letter-spacing: -0.88px;
        }
      }

      .order-btn {
        margin-top: 12px;
      }
    }
  }
}
