@use '../custom-variables/colors' as *;
@use '../custom-variables/grid';

.dropdown-menu {
  box-shadow: $dropdown-box-shadow;
}

.hover-placeholder {
  &::before {
    content: '';
    background-color: transparent !important;
    display: block;
    height: 38px;
    position: absolute;
    top: -38px;
    width: 100%;

    @media (max-width: grid.get-grid-bp('xl')) {
      top: 3px;
      height: 28px;
    }

    @media (max-width: grid.get-grid-bp('lg')) {
      top: -14px;
      height: 28px;
    }

    // @media (max-width: grid.get-grid-bp('md')) {
    //   top: 0px;
    //   height: 25px;
    // }
  }
}

.left-section {
  padding: 1.3em 0;
  max-width: 620px;
  min-width: 620px;

  .icon {
    color: $dropdown-ls-icon-color;
  }

  .navigation-link {
    padding: 1em 0;
    white-space: nowrap !important;

    &:hover {
      background: $dropdown-ls-link-hover-bg;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;

      i {
        background: $dropdown-ls-icon-hover-bg;
        background-color: rgba(217, 217, 217, 0.2);
      }
    }

    i {
      padding: 13px 21px 13px 55px;
      border: $dropdown-ls-icon-border-color;
      border-width: 2px;
      border-style: solid;
      border-bottom-right-radius: 20px;
      border-top-right-radius: 20px;
      border-left: transparent;
    }

    .link-text {
      margin-left: 21px;
    }

  }

  .link-text {
    .title {
      color: $dropdown-ls-link-title-color;
      font-size: 20px;
      font-weight: 600;

      line-height: 20px;
    }

    .sub-text {
      color: $dropdown-ls-link-sub-title-color;
      font-size: 17px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.right-section {
  padding-top: 49px;
  padding-left: 49px;
  padding-right: 42px;
  max-width: 508px;
  min-width: 508px;

  @media (max-width: grid.get-grid-bp('xl')) {
    min-width: unset;
  }

  @media (max-width: grid.get-grid-bp('lg')) {
    display: none;
  }

  .nav-menu-section {
    .icon {
      color: $dropdown-rs-icon-color;
      font-size: 50px;
    }

    .title {
      color: $dropdown-rs-title-color;
      font-size: 25px;
      font-weight: 600;

      line-height: 32px;
      margin: 20px 0 30px;
    }

    .text {
      color: $dropdown-rs-text-color;
      font-size: 17px;
      font-weight: 400;
      line-height: 20px;

    }

    .sub-text {
      color: $dropdown-rs-sub-text-color;
      font-size: 17px;
      font-weight: 400 !important;
      line-height: 20px;

    }
  }
}

.custom-dropdown {
  .dropdown-menu[data-bs-popper] {
    top: 14%;
    left: 27%;

    @media (max-width: grid.get-grid-bp('lg')) {
      top: 20%;
    }

    @media (max-width: 1050px) {
      left: 20%;
    }
  }
}

.custom-dropdown {
  .dropdown-button {
    background: none;
    border: none;
    color: $custom-dropdown-text-color;

    &.with-arrow {
      position: relative;

      &::after {
        content: "";
        width: 0;
        height: 0;
        border-color: transparent transparent #FFFFFF;
        border-style: solid;
        border-width: 0 25px 43px;
        position: absolute;
        display: inline-block;
        bottom: -50px;

        opacity: 0;
        visibility: hidden;
        z-index: 1;

        @media (max-width: grid.get-grid-bp('md')) {
          display: none;
        }
      }
    }
  }

  .custom-dropdown-menu {
    display: none;

    padding-inline: 1rem;
    list-style: none;

    &.show {
      display: block;
      position: absolute;
    }
  }
}

.with-arrow.additional-services-connector-color {
  &::after {
    border-color: transparent transparent #e6e8f1 !important;
  }
}

.with-arrow.show {
  &::after {
    opacity: 1 !important;
    visibility: visible !important;
  }
}

.nav-right-block-price {
  font-weight: 600;

  .prefix {
    display: block;
    margin-bottom: 10px;
  }

  .price {
    font-size: 30px;
  }

  &_domain {
    .prefix {
      font-weight: 600;
      display: inline;
      margin-bottom: 10px;
      font-size: 20px;
    }

    .price {
      font-weight: 600;
      font-size: 30px;
    }

    .watermark {
      font-weight: 600;
      font-size: 30px;
    }
  }
}

$default-backgrounds: (
  'hosting-menu': '/img/bg/default-hosting-menu-bg.svg',
  'vps-menu': '/img/bg/default-vps-menu-bg.svg',
  'domain-menu': '/img/bg/default-domain-menu-bg.svg',
  'server-menu': '/img/bg/default-server-menu-bg.svg',
  'other-menu': '/img/bg/default-other-menu-bg.svg'
);

@mixin defult-background-for-right-section ($url) {
  background: url($url) no-repeat;
  background-size: cover;
  border-radius: 0 20px 20px 0;
}

@each $name,
$url in $default-backgrounds {
  .default-#{$name}-bg {
    @include defult-background-for-right-section($url);
  }
}

.default-server-menu-bg {
  min-height: 424px;
}

.nav-services {
  .custom-dropdown .container {
    margin: 0;
    padding: 0;
  }

  .navigation-link {
    padding-right: 57px;
    white-space: nowrap !important;

    &:hover {
      background: $nav-services-link-hover-bg;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;

      i {
        background: $nav-services-icon-hover-bg;
        background-color: rgba(217, 217, 217, 0.2);
      }
    }

    i {
      padding: 13px 21px 13px 55px;
      border: $nav-services-icon-border-color;
      border-width: 2px;
      border-style: solid;
      border-bottom-right-radius: 20px;
      border-top-right-radius: 20px;
      border-left: transparent;
    }

    .link-text {
      margin-left: 21px;
    }

  }

  .right-section {
    .nav-menu-section {
      display: none;

      &.active {
        display: block;
      }
    }
  }
}
