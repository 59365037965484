@use '../custom-variables/grid';

.comfort-section {
  padding-top: 8vw;
  padding-bottom: 11.4vw;

  background: url('/img/bg/comfort-section.svg') no-repeat;
  background-size: contain;

  .header-box {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    margin-bottom: 4.2vw;

    .header {
      max-width: 830px;
      margin: 0;

      font-weight: 600;
    //   font-size: 40px;
      line-height: 50px;
      letter-spacing: -0.88px;
    }
  }

  .comfort-boxes {
    display: flex;
    align-items: start;
    justify-content: space-between;
  }

  .comfort-box {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      display: flex;
      align-items: center;

      margin-bottom: 14px;

      .img-into-svg {
        i {
          font-size: 30px;
        }
      }

      .title-text {
        font-weight: 600;
        font-size: 48px;
        line-height: 55px;
        letter-spacing: -0.88px;

        white-space: nowrap;

        margin-left: 10px;
      }
    }

    .undertitle {
      max-width: 300px;
      text-align: center;

      font-weight: 400;
      font-size: 20px;
      line-height: 24px;

    }
  }


  @media (max-width: grid.get-grid-bp('lg')) {
    background: unset;

    .comfort-boxes {
      flex-direction: column;
      align-items: center;
    }

    .comfort-box {
      margin-bottom: 4rem;
    }
  }

  @media (max-width: grid.get-grid-bp('md')) {
    .comfort-box {
      margin-bottom: 2rem;

      .title {
        flex-direction: column;

        .title-text {
          margin: 0;
        }
      }
    }
  }
}
