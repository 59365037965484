@use './bootstrap';

@use './fonts';
@use './swiper';
@use './includes/inc_bootstrap';
@use './partials/partials_bootstrap';

.block-arrow {
  position: relative;
  --arrow-size: 6.15em;
  --arrow-height: 1.15em;
  --arrow-color: white;

  &::before {
    content: "";

    position: absolute;

    top: 115%; // .lower-navigation height

    filter: drop-shadow(-2px 8.5px 5px rgba(9, 9, 9, 0.06));

    border-top: var(--arrow-height) solid var(--arrow-color);
    border-right: var(--arrow-size) solid transparent;
    border-left: var(--arrow-size) solid transparent;
    border-bottom: 0;
  }
}

.block-mirror {
  transform: scaleX(-1);
}

.embed-modal {
  min-height: 500px;
  padding: 0 !important;
}

.embed-responsive-item {
  border: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  border-radius: var(--bs-modal-border-radius);
}
