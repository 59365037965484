@use '../custom-variables/colors' as *;
@use '../custom-variables/grid';

.our-tenders-section {

  .tender-header {
    text-align: center;

    .title {
      * {
        font-weight: 600;
        // font-size: 41px;
        line-height: 30px;

        margin: 0;
      }

      margin-bottom: 47px;
    }

    .sub-title {
      display: flex;
      align-items: center;
      justify-content: center;

      margin-bottom: 51px;

      font-weight: 300;
      font-size: 30px;
      line-height: 55px;
      letter-spacing: -0.88px;

      .prozoro-logo {
        margin-left: 26px;

        background: $our-tenders-section-prozoro-logo-bg;
        border-radius: 20px;

        padding-right: 23px;
        padding-left: 26px;
      }

      @media (max-width: grid.get-grid-bp('md')) {
        flex-direction: column;

        .prozoro-logo {
          margin: 0;
        }
      }
    }
  }

  .tender-table {}
}
