@use '../custom-variables/colors' as *;
@use 'cards';

.page-promo-card {
  @include cards.card-shadow;

  border-radius: 25px;
  background: $page-promo-card-bg;

  text-decoration: none;
  color: unset;

  display: flex;
  flex-direction: column;

  padding-top: 40px;
  padding-inline: 24px;
  padding-bottom: 30px;

  max-width: 312px;
  min-width: 312px;
  width: 100%;

  max-height: 444px;
  min-height: 444px;
  height: 100%;

  .promo-card {
    &-head {
      @include cards.promo-card-head;

      min-height: 44px;
    }

    &-body {

      .price-block,
      .card-image-block {
        height: 73px;

        margin-top: 24px;
        margin-bottom: 14px;
      }

      .card-image-block {
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          font-size: 73px;
        }
      }

      .price-block {
        display: flex;
        align-items: baseline;

        >span {
          line-height: 1.3;

          >.prefix {
            font-weight: 600;
            font-size: 16px;

          }

          >.price {
            font-weight: 600;
            font-size: 40px;
            letter-spacing: -0.88px;
          }

          >.watermark,
          >.sufix {
            font-size: 20px;
            font-weight: 600;
          }

          .sufix {
            font-weight: 600;
            font-size: 20px;
            line-height: 33px;
            letter-spacing: -0.88px;
          }

          &.with-sufix {
            >.price {
              color: $page-promo-price-color;
              font-weight: 600;
              font-size: 1.3rem;
              line-height: 33px;
              letter-spacing: -0.88px;
              text-decoration-line: line-through;
            }

            >.watermark {
              color: $page-promo-price-color;
              font-weight: 400;
              font-size: 18px;
              line-height: 25px;
            }

            .sufix {
              display: block;
              width: 100%;

              >span {
                .price {
                  font-weight: 600;
                  font-size: 32px;
                  line-height: 1;
                  letter-spacing: -0.88px;
                }

                .watermark,
                .sufix {
                  display: unset;
                  width: unset;

                  font-size: 20px;
                  font-weight: 600;
                  line-height: 1;
                  letter-spacing: -0.88px;
                }
              }
            }
          }
        }
      }

      .benefits-block {
        @include cards.card-benefits;
      }
    }

    &-footer {
      margin-top: auto;
      color: $card-arrow-color;

      align-self: end;

      i {
        font-size: 23px;
      }

      &.with-btn {
        align-self: center;

        .button {
          //   margin-inline: auto;
          max-width: 159px;
        }
      }
    }
  }

  &:hover {
    color: unset;

    .promo-card-footer {
      i {
        color: $page-promo-promo-card-footer-icon-color;
      }

      &.with-btn {
        .button {
          color: $page-promo-promo-card-footer-btn-text-active-color;
          background-color: $page-promo-promo-card-footer-icon-color;
          box-shadow: 0px 4px 7px rgba(54, 91, 149, 0.7490196078);

          &:active {
            color: $page-promo-promo-card-footer-btn-text-active-color;
            padding: 4px 29px;
            border: unset;
            background-color: $page-promo-promo-card-footer-icon-color;
            box-shadow: inset 0px 4px 7px rgba(54, 91, 149, 0.5019607843);
          }
        }
      }
    }
  }
}
