@use '../custom-variables/colors' as *;
@use '../custom-variables/grid';

.reviewers-block {
  .reviewers {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: grid.get-grid-bp('md')) {
      flex-direction: column;

      .reviewer {
        margin-right: 0 !important;

        &:not(:first-child) {
          margin-top: 40px;
        }
      }
    }

    .reviewer {
      display: flex;
      align-items: center;
      flex-direction: column;

      &:not(:last-child) {
        margin-right: 95px;
      }

      .reviewer-img {
        img {
          height: 50px;

          mix-blend-mode: multiply;
          filter: grayscale(1);
        }

        margin-bottom: 15px;
      }

      .reviewer-stars {
        color: $reviewers-block-reviewer-stars-color;

        font-size: 24px;

        i {
          margin-left: 5px;
        }
      }
    }
  }
}
