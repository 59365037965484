@use '../custom-variables/colors' as *;

$border-radius: 10px;

.tenders-table {
    margin: 0;

  * {

    td,
    th {
      color: $tenders-table-col-text-color;
      text-align: center;

      padding: 6.5px 10px;

      &:nth-child(2) {
        width: 35%;
      }

      &:nth-child(3) {
        width: 40%;
      }
    }
  }

  thead {
    tr {

      td,
      th {
        background: $tenders-table-head-col-bg;
        border: unset;

        font-weight: 600;
        font-size: 20px;
        line-height: 31px;
        letter-spacing: -0.88px;

        &:first-child {
          border-top-left-radius: $border-radius;
          border-bottom-left-radius: $border-radius;

          padding-left: 15px;
        }

        &:last-child {
          border-top-right-radius: $border-radius;
          border-bottom-right-radius: $border-radius;

          padding-right: 35px;
        }
      }
    }
  }

  tbody {
    tr {

      &:hover {

        th,
        td {
          background: $tenders-table-body-col-bg;

          &:first-child {
            border-top-left-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
          }

          &:last-child {
            border-top-right-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
          }
        }
      }

      th,
      td {
        height: 57px;

        border: unset;

        padding-block: 3.5px;

        font-weight: 400;
        font-size: 18px;
        line-height: 25px;


        white-space: nowrap;

        vertical-align: middle;

        &:first-child {
          padding-left: 14px;
        }

        &:last-child {
          padding-right: 23px;
        }

        &:nth-child(2),
        &:nth-child(3) {
          white-space: normal;
          text-align: start;
        }
      }
    }
  }

  tfoot {
    tr {

      td,
      th {
        border: unset;

        padding-top: 48px;

        color: $tenders-table-footer-col-text-color;

        text-decoration: underline;

        font-weight: 600;
        font-size: 20px;
        line-height: 40px;


        * {
          cursor: pointer;
        }
      }
    }
  }
}
