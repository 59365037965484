@use '../custom-variables/colors' as *;
@use '../custom-variables/grid';
@use '../custom-variables/button-variables' as btn;

.header-section {}

.secondary-nav-section {
  background: $header-secondary-nav-bg;
}

.main-header-section {
  padding-top: 4rem;
  padding-bottom: 0;

  background: linear-gradient(to top, #A1C4FD, #C2E9FB);

  .page-header-text,
  .page-sub-header-text,
  .page-price-block {
    color: $header-page-title-and-price-color;
  }

  .page-desc-block {
    .page-desc-text {
      color: $header-page-desc-text;
      margin: 0;

      font-size: 20px;
      font-weight: 600;
      line-height: 1.3;
    }
  }

  .page-header-block {
    margin-bottom: 1rem;

    .page-header-text,
    .page-sub-header-text {
      margin: 0;
      line-height: 50px;
      font-weight: 600;
    }
  }

  .page-text-block {
    color: $header-page-text-color;

    font-size: 20px;
    font-weight: 400;
    line-height: 1.3;

    margin-bottom: 1.3rem;

    >* {
      margin: 0;
      font: inherit;
    }
  }

  .page-benefits-block {
    display: flex;
    flex-direction: column;
    align-items: start;

    margin-bottom: 2.5rem;

    .benefit {
      display: flex;
      align-items: center;

      margin: 0;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      font-size: 20px;
      font-weight: 400;
      line-height: 1.3;

      color: $header-page-benefits-text;

      i {
        margin-right: 10px;

        --icon-size: 24px;

        max-width: var(--icon-size);
        max-height: var(--icon-size);
        font-size: var(--icon-size);

        color: $header-page-benefits-icon;
      }

      >*:not(i) {
        font: inherit;
      }

      .benefit-text {
        margin: 0;

        a {
          color: inherit;
        }
      }
    }
  }

  .page-price-block {
    font-weight: 600;
    margin-bottom: 1rem;

    .prefix {
      font-size: 20px;
      line-height: 1.3;
    }

    .price {
      font-size: 60px;
      line-height: 1.3;
    }

    .watermark {
      font-size: 24px;
      line-height: 1.3;
    }
  }

  .rounded-block {
    display: flex;
    flex-direction: column;
    align-items: center;

    border: $header-rounded-block-border;
    border-radius: 40px;

    max-height: 146px;
    min-width: 482px;
    height: 100%;
    width: max-content;

    @media (max-width: grid.get-grid-bp('lg')) {
      width: 100%;
      min-width: unset;
    }

    @media (max-width: grid.get-grid-bp('sm')) {
      max-height: unset;
      height: auto;
    }

    color: $header-page-title-and-price-color;

    &.with-text {
      padding-block: 2rem;
      padding-inline: 120px;

      margin-bottom: 2rem;

      span {
        font-weight: 600;
        letter-spacing: -0.88px;

        &:first-child {
          font-size: 30px;
        }

        &:last-child {
          font-size: 20px;
        }
      }

      @media (max-width: grid.get-grid-bp('sm')) {
        padding-inline: 20px;
      }
    }

    &.with-price {
      padding: 24px 27px;

      @media (max-width: grid.get-grid-bp('lg')) {
        padding: 24px 24px;
      }

      margin-bottom: 1rem;

      .price-row {
        display: flex;
        align-items: center;

        width: 100%;

        &:first-child {
          .text-block {
            padding-left: 160px;
          }
        }

        @media (max-width: grid.get-grid-bp('lg')) {
          &:first-child {
            .text-block {
              padding-left: 0;
            }
          }
        }

        .text-block {
          text-align: end;

          font-size: 20px;
          font-weight: 600;

          @media (max-width: grid.get-grid-bp('lg')) {
            width: 40%;
          }

          @media (max-width: grid.get-grid-bp('sm')) {
            width: unset;
          }
        }

        &:last-child {
          .text-block {
            font-size: 30px;
          }
        }

        .price-block {
          text-align: start;
          letter-spacing: -0.88px;
          font-weight: 600;
          line-height: 30px;

          margin-left: 25px;

          @media (max-width: grid.get-grid-bp('lg')) {
            width: 60%;
          }

          @media (max-width: grid.get-grid-bp('sm')) {
            width: unset;
          }
        }

        &:first-child {
          margin-bottom: 1em;

          .price-block {
            .price {
              font-size: 20px;
            }

            .watermark,
            .sufix {
              font-size: 16px;
            }
          }
        }

        &:last-child {
          .price-block {
            .price {
              font-size: 60px;
            }

            .watermark,
            .sufix {
              font-size: 24px;
            }
          }
        }
      }

      @media (max-width: grid.get-grid-bp('sm')) {
        .price-row {
          flex-direction: column;

          >*,
          & {
            width: auto;
          }
        }
      }
    }
  }

  .domain-search-form-block {
    margin-top: auto;

    border-radius: 20px 20px 0px 0px;
    background: $header-domain-search-form-block-bg;

    padding-inline: 4%;
    padding-block: 1rem;
  }

  .domain-check {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    .domain-check-label {
      color: $under-header-section-domain-search-form-block-label;

      font-size: 22px;
      font-weight: 600;
      line-height: 1.3;

    }

    .domain-input {
      max-width: 450px;
    }

    .actions-block {
      justify-content: space-between;
    }

    @media (max-width: grid.get-grid-bp('lg')) {
      align-items: start;
    }

    @media (max-width: grid.get-grid-bp('md')) {
      align-items: center;
    }
  }
}

.header-right-section {
  .cms-icon {
    font-size: 10rem;
    color: $header-right-section-cms-icon;
    z-index: 2;
  }

  &.with-image {
    padding-bottom: 1.6em;
  }

  &.with-logo {
    display: flex;
    align-items: center;
    justify-content: center;

    .logo-block {
      align-items: center;
      display: flex;
      justify-content: center;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        display: block;

        height: 400px;
        width: 440px;

        background: url('/img/bg/logo-bg.svg') no-repeat;
        background-size: contain;

        z-index: 1;
      }

      img {
        max-width: 220px;
        position: relative;
        z-index: 2;
      }
    }
  }

  &.with-person {
    .img-block {
      min-width: 448px;

      img {
        max-height: 571px;
        position: relative;
      }

      position: relative;

      &::before {
        content: '';
        position: absolute;
        display: block;

        background: url('/img/bg/people-bg.svg') no-repeat;
        height: 548px;
        width: 495px;
      }
    }
  }

  &.with-card {
    position: relative;

    @media (min-width: grid.get-grid-bp('lg')) {
      &::before {
        content: '';
        position: absolute;

        background: url('/img/bg/card-arial.svg') no-repeat;

        width: 341px;
        height: 442px;

        top: -50px;
        left: 1.3rem;
      }
    }

    .promo-card {
      position: absolute;
      top: 0;
    }
  }
}

.under-header-section {

  &.top-domains,
  &.domain-search,
  &.page-benefits-underheader {
    background: $under-header-section-bg;
  }

  &.top-domains {
    .top-domains-block {
      display: flex;
      align-items: baseline;

      color: $under-header-section-top-domains-text;

      padding-block: 5px;

      .title {
        font-size: 18px;
        font-weight: 600;
        line-height: 1.3;
      }

      .top-domains {
        display: flex;
        align-items: baseline;

        .top-domain {
          display: flex;
          align-items: baseline;

          .prefix {
            display: flex;
            align-items: baseline;

            .name {
              color: $under-header-section-top-domains-text;
              text-underline-offset: 4px;
            }
          }

          margin-left: 44px;
        }
      }

      @media (max-width: grid.get-grid-bp('lg')) {
        flex-direction: column;
        align-items: center;

        .top-domains {
          .top-domain {
            &:first-child {
              margin-left: 0;
            }
          }
        }
      }

      @media (max-width: grid.get-grid-bp('sm')) {
        .top-domains {
          flex-wrap: wrap;

          gap: 20px 0;

          .top-domain {
            flex: 0 0 50%;

            &:nth-child(odd) {
              justify-content: start;
            }

            &:nth-child(even) {
              justify-content: end;
            }

            margin-left: 0;
          }
        }
      }
    }
  }

  .page-benefits {
    padding-block: 20px;

    .page-benefit {
      display: flex;
      align-items: center;
      color: $under-header-section-text-color;

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;

        color: $under-header-section-icon-color;

        box-shadow: $under-header-section-page-benefits-icon-shadow;
        border-radius: 50%;

        padding: 35px;

        font-size: 40px;

        --icon-size: 90px;

        height: var(--icon-size);
        width: var(--icon-size);
      }

      .benefit-text {
        margin: 0;

        margin-left: 24px;

        font-size: 1rem;
        font-weight: 300;
        line-height: 1.3;
      }

      @media (max-width: grid.get-grid-bp('lg')) {
        justify-content: start;

        &:not(:last-child) {
          margin-bottom: 2rem;
        }
      }

      @media (max-width: grid.get-grid-bp('sm')) {
        .icon {
          --icon-size: 70px;

          font-size: 30px;
        }

        .benefit-text {
          margin-left: 1rem;
        }

        .domain-in-zone {
          font-size: 22px;
          line-height: 1.3;
          font-weight: 600;
        }

        .all-domains-link {
          font-size: 16px;
          line-height: 1.3;
          font-weight: 400;
        }
      }
    }
  }
}

.domain-search {
  padding-block: 1.5rem;

  .domain-check {
    display: flex;
    align-items: center;

    .domain-check-label {
      display: flex;
      flex-direction: column;
      align-items: start;

      font-weight: 600;

      >* {
        margin: 0;
        color: $under-header-section-domain-search-label;
      }

      .domain-in-zone {
        font-size: 30px;
        line-height: 1.3;
      }

      .all-domains-link {
        font-size: 18px;
        line-height: 1.3;
        border: 0;
        background: unset;
        text-decoration: underline;
      }
    }

    .domain-input {
      margin-left: 3rem;
      border-radius: 30px;
    }

    .domain-check-submit-btn {
      margin-left: 1.5rem;
    }

    //DISABLED: clunky width on mobile device
    // .actions-block {
    //   width: 70%;
    // }
  }

  .domain-search-output {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 1rem;

    padding-inline: 33px 67px;
    padding-block: 30px;

    box-shadow: inset 0px 4px 4px 0px rgba(0, 0, 0, 0.15);

    background: $under-header-section-domain-search-output-bg;

    border-radius: 55px;

    color: $under-header-section-domain-search-output-text;

    .search-info,
    .domain-action-block {
      display: flex;
      align-items: center;
    }

    .search-info {

      .icon {
        display: flex;

        >* {
          font-size: 52px;
        }
      }
    }

    .domain-status {
      display: flex;
      align-items: baseline;

      margin-left: 2rem;

      .searchable-domain,
      .search-status {
        margin-bottom: 0;
      }

      .searchable-domain,
      .search-status {
        font-size: 30px;
        font-weight: 400;
        letter-spacing: -0.88px;
      }

      .search-status {
        margin-left: 0.5rem;

        &.free {
          color: $search-status-free;
        }

        &.buzy {
          color: $search-status-busy;
        }
      }
    }

    .domain-action-block {
      .price-block {
        font-size: 30px;
        font-weight: 400;
        line-height: 45px;
        letter-spacing: -0.88px;

        .watermark {
          margin: 0;
        }
      }

      .order-btn {
        .button {
          font-size: 30px;
          font-weight: 400;
          line-height: 45px;
          letter-spacing: -0.88px;
        }
      }
    }
  }

  @media (max-width: grid.get-grid-bp('lg')) {
    .domain-check {
      flex-direction: column;

      .domain-check-label {
        align-items: center;
      }

      .domain-input,
      .domain-check-submit-btn {
        margin: 0;
      }

      .domain-input {
        padding-block: 15px;
        line-height: 40px;
        height: auto;

        margin-top: 2rem;
      }

      .domain-check-submit-btn {
        margin-top: 2.5rem;

        max-width: 546px;

        &,
        .button {
          width: 100%;
        }

        .button {
          --bs-btn-padding-y: #{btn.$btn-padding-y-lg};
          --bs-btn-padding-x: #{btn.$btn-padding-x-lg};
          // --bs-btn-font-size: #{btn.$btn-padding-y-lg};
          --bs-btn-line-height-lg: #{btn.$btn-line-height-lg};
          --bs-btn-border-radius: 75px;
          line-height: var(--bs-btn-line-height-lg);
        }
      }
    }

    .domain-search-output {
      flex-direction: column;
      align-items: first baseline;
      overflow: hidden;
      text-overflow: ellipsis;

      .search-info {
        .domain-status {
          flex-direction: column;
          margin-left: unset;

          .searchable-domain {
            margin-left: unset;
            font-size: 20px;
          }

          .search-status {
            margin-left: unset;
            font-size: 20px;
          }
        }

        .icon {
          display: none;
        }
      }

      .domain-action-block {
        flex-direction: column;
        align-items: unset;

        .domain-price {
          font-size: 20px;
        }

        .button {
          padding: unset;
          font-size: 20px !important;
        }
      }
    }
  }
}
