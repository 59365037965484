@use '../hyper-icons/icon-variables' as icon;

table.sortable {
  thead {
    tr {
      th {
        &:not(.no-sort) {
          cursor: pointer;

          .arrow-icons {
            font-family: 'hyper-icons';

            position: relative;

            &::before {
              content: icon.$hypericon-sort-solid;
              position: absolute;
              font-size: 12px;
            }
          }
        }

        &.dir-u {
          .arrow-icons {
            &::before {
              content: icon.$hypericon-sort-up-solid;
            }
          }
        }

        &.dir-d {
          .arrow-icons {
            &::before {
              content: icon.$hypericon-sort-down-solid;
            }
          }
        }
      }
    }
  }
}
